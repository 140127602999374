import React, {useEffect, useState} from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { easyFixService } from "../../api/services";
import SpinLoader from "../../components/loaders/Spin";

const Generate = () => {
    let navigate = useNavigate();
    const [ applicationStarted, setApplicationStarted ] = useState(false);

    useEffect(() => {
        if (!applicationStarted) {
            setApplicationStarted(true);
            startNewApplication();
        }
    }, []);

    const startNewApplication = async () => {
        const resp = await easyFixService.startApplication();
        if (resp.status == 'success') {
            if (resp.data?.application_id) {
                navigate(`/application/${resp.data.application_id}`);
            }
        }
    }


    return (
        <div className='w-full h-full flex flex-col items-center justify-center py-10 px-14' style={{ minHeight: 'calc(100vh - 160px)' }}>
            <SpinLoader
                text={'Loading'}
            />
        </div>
    );
};

export default Generate;
