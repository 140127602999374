import React, { ReactElement, useEffect } from 'react';
import SideBar from '../sidebar';
import Footer from '../Footer';
import useApplication from '../../hooks/useApplication';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import useResponsiveness from '../../hooks/useResponsiveness';
import SpinLoader from '../loaders/Spin';

interface Props {
    children: Array<ReactElement> | ReactElement;
}

const Container = ({ children }: Props) => {
    const { application, applicationStatus, setApplicationStatus, setApplication,setApplicationLoading, applicationLoading} = useApplication();
    const navigate = useNavigate();
    const location = useLocation();
    const { isMobile } = useResponsiveness();
    const { applicationId } = useParams();

    const getCookie = () => {
        const cookies = document.cookie.split(';');
        const cookieMap: { [key: string]: string } = {}; // Explicitly type cookieMap
        cookies.forEach(cookie => {
            const [name, value] = cookie.trim().split('=');
            cookieMap[name] = value;
        });


        return cookieMap['easyfix_application_id'] || false; // Replace 'cookieName' with the name of your cookie
    };

    useEffect(() => {
        const app_id_from_cookie = getCookie();
        if (!application.application_id && app_id_from_cookie) {
            if (app_id_from_cookie) {
                setApplication({application_id: app_id_from_cookie, step: 1, fields: {}})
            }
        } else {
            const path = location.pathname;
            if (!path.includes('error') && !path.includes('application') && !path.includes('generate') && !application.application_id) {
                if (applicationId) {
                    setApplication({application_id: applicationId, step: 1, fields: {}})
                } else {
                    navigate('/generate');
                }
                return;
            }
        }
    }, [application, applicationStatus]);

    return (
        <div className='flex w-full h-full bg-white flex-col'>
            <main className='flex flex-row bg-white overflow-y-scroll'>
                <SideBar />
                {applicationLoading ? <>
                    {isMobile ? (
                        <div className='flex-1 lg:py-24 lg:pl-[20rem] lg:pr-8' id='content' style={{maxWidth: 1920, paddingTop:'500px'}}>
                            <div className='w-full h-full flex flex-col items-center justify-center py-10 px-14' style={{ minHeight: 'calc(100vh)' }}>
                                 <SpinLoader
                                      text={'Loading'}
                                    />
                            </div>
                        </div>
                    ) : (
                        <div className='flex-1 pt-32 lg:pt-24 lg:pl-[20rem] lg:pr-8' id='content' style={{maxWidth: 1920}}>
                            <div className='w-full h-full flex flex-col items-center justify-center py-10 px-14' style={{ minHeight:"90vh" }}>
                                <SpinLoader
                                    text={'Loading'}
                                />
                            </div>
                        </div>
                    )}
                </>  : <>                
                    {isMobile ? (
                        <div className='flex-1 lg:py-24 lg:pl-[20rem] lg:pr-8' id='content' style={{maxWidth: 1920, paddingTop:'500px'}}>
                            {children}
                        </div>
                    ) : (
                        <div className='flex-1 pt-32 lg:pt-24 lg:pl-[20rem] lg:pr-8' id='content' style={{maxWidth: 1920,minHeight:"85vh"}}>
                            {children}
                        </div>
                    )}
                </>}
            </main>
            <Footer/>
        </div>
    );
};

export default Container;
