import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tick from '../Buttons/Tick';
import { useState } from 'react';
import { NavLink, useHref, useNavigate, useLocation } from 'react-router-dom';
import useApplication from '../../hooks/useApplication';
import { Cancel, Check, CheckCircle } from '@material-ui/icons';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useSwipeOrScrollDirection from '../../hooks/useSwipeOrScrollDirection';

const drawerWidth = 345;

const steps = [
    { slug: 'welcome', name: 'Welcome' },
    { slug: 'personal-details', name: 'Personal Details' },
    //{ slug: 'choose-cover', name: 'Choose Cover' },
    // { slug: 'appliance-details', name: 'Appliance Details' },
    { slugs: ['payment-details','debit-order-mandate',], name: 'Payment Details' },
    { slug: 'complete', name: 'Congrats! Your covered!' }
]

export default function SideBar() {
    const [completedSteps, setCompletedSteps] = useState(new Set());
    const { applicationStatus } = useApplication();
    const navigate = useNavigate();
    const location = useLocation();
    const [expanded, setExpanded] = useState<string | false>(false);
    const width = window.innerWidth;
    const [ up, down ] = useSwipeOrScrollDirection();

    const currentStep = steps.find(step => {
        if(step.slug)
            return location.pathname.includes(step.slug);
        else {
            for(let slug of step.slugs ? step.slugs : []){
                let result = location.pathname.includes(slug)
                if(result)return result;
            }
        }
        return false;
    });
    
    const currentStepName = currentStep ? currentStep.name : 'Select a Step';
    const { pathname } = window.location;

    const handleChange = (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };


    const completeStep = (currentStepIndex: number) => {
        setCompletedSteps(prevSteps => new Set(prevSteps).add(steps[currentStepIndex]));
        const nextStepIndex = currentStepIndex + 1 < steps.length ? currentStepIndex + 1 : 0;
        navigate(`/${steps[nextStepIndex]}`);
    };

    const goBack = () => {
        window.history.back();
    }

    const showBorder = (step:any) =>{
        if(step.slug){
            return location.pathname.includes(step.slug);
        }
        if(step.slugs){
            if(step?.slugs)
            for(let slug of step.slugs ? step.slugs : []){
                let result = location.pathname.includes(slug)
                if(result)return result;
            }
        }
        return false;
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            
            {width < 768 ? (
                <div className={'w-full h-full'} style={{height:'350px'}}>
                    <div
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '300px',
                            boxSizing: 'border-box',
                            background: `url('/img/bg-main.png')`,
                            transition:"all 0.5s",
                            backgroundPosition:(()=>{
                                if(up)return "50% 45%";
                                if(down)return"50% 60%";
                                return "50% 45%"
                            })(),
                            backgroundSize: 'cover',
                            borderTop: '5px solid #FF003E',
                        }}
                    >
                        <div style={{
                    position:"absolute",
                    top:"0px",
                    left:"0px",
                    width:"100%",
                    height:"100%",
                    backgroundImage:"linear-gradient(transparent,#00000095)"
                }}/>
                        <Toolbar />
                        <img src='/img/logo-main.png' style={{ width: '90%',transition:"margin 1s",zIndex:"2",position:"relative", margin: down ? "55px auto":"50px auto" }} alt="Logo" />
                    </div>
                    <AppBar
                        className='p-3'
                        position="absolute"
                        sx={{ width: '100%', background: '#F7F7F7', top: '300px' }}>
                        {pathname !== '/complete' && pathname !== '/welcome' ? <div onClick={goBack} style={{cursor: 'pointer', zIndex:'10'}} className='absolute top-7 left-4 flex justify-center p-2 h-8 w-8 rounded-full bg-grey-d'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" className="w-4 h-4 hover:fill-green cursor-pointer">
                                <path fillRule="evenodd" d="M17 10a.75.75 0 0 1-.75.75H5.612l4.158 3.96a.75.75 0 1 1-1.04 1.08l-5.5-5.25a.75.75 0 0 1 0-1.08l5.5-5.25a.75.75 0 1 1 1.04 1.08L5.612 9.25H16.25A.75.75 0 0 1 17 10Z" clipRule="evenodd" />
                            </svg>
                        </div> : null}
                        <Toolbar className='flex justify-center md:justify-start' style={{ overflow: 'hidden' }}>
                            <Typography>{currentStepName}</Typography>
                            {/*<Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>*/}
                            {/*    <AccordionSummary expandIcon={<ExpandMoreIcon />}>*/}
                            {/*        <Typography>{currentStepName}</Typography>*/}
                            {/*    </AccordionSummary>*/}
                            {/*    <AccordionDetails style={{ maxHeight: '200px', overflowY: 'auto' }} className='flex flex-col gap-1'>*/}
                            {/*        {steps.map((step, index) => (*/}
                            {/*            <Typography*/}
                            {/*                key={step.slug}*/}
                            {/*                sx={{*/}
                            {/*                    cursor: 'pointer',*/}
                            {/*                    typography: 'body2',*/}
                            {/*                    color: location.pathname.includes(step.slug) ? 'primary.main' : 'text.primary',*/}
                            {/*                    padding: '8px 5px',*/}
                            {/*                }}*/}
                            {/*            >*/}
                            {/*                {step.name}*/}
                            {/*            </Typography>*/}
                            {/*        ))}*/}
                            {/*    </AccordionDetails>*/}
                            {/*</Accordion>*/}
                        </Toolbar>
                    </AppBar>
                </div>
            ) : (
                <AppBar
                    className='p-3'
                    position="fixed"
                    sx={{
                        width: `calc(100% - ${width > 768 ? drawerWidth : 0}px)`,
                        ml: `${width > 768 ? drawerWidth : 0}px`,
                        background: '#F7F7F7',
                        height: '80px',
                        left: '0px'
                    }}>
                    {pathname !== '/complete' && pathname !== '/welcome' ? <div onClick={goBack} style={{cursor: 'pointer', zIndex:'10'}}
                                                                className='absolute top-7 left-4 flex justify-center p-2 h-8 w-8 rounded-full bg-grey-d'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white"
                             className="w-4 h-4 hover:fill-green cursor-pointer">
                            <path fillRule="evenodd"
                                  d="M17 10a.75.75 0 0 1-.75.75H5.612l4.158 3.96a.75.75 0 1 1-1.04 1.08l-5.5-5.25a.75.75 0 0 1 0-1.08l5.5-5.25a.75.75 0 1 1 1.04 1.08L5.612 9.25H16.25A.75.75 0 0 1 17 10Z"
                                  clipRule="evenodd"/>
                        </svg>
                    </div> : null}
                    <Toolbar className='flex justify-evenly w-full gap-8 md:gap-0'
                             style={{...(width < 768 && {width, overflow: 'hidden'})}}>
                                
                        {applicationStatus !== 'in-progress' ?
                            <div className='w-full h-full flex flex-col items-center justify-center'>
                                {applicationStatus === 'complete' ? <CheckCircle style={{fill: 'green'}}/> :
                                    <Cancel style={{fill: 'red'}}/>}
                                <p>{applicationStatus === 'complete' ? 'Complete' : applicationStatus === 'declined' ? 'Declined' : 'Error'}</p>
                            </div> : steps.map((step,index) => (
                                <div
                                    className={`flex gap-2 items-baseline text-xs ${ showBorder(step) ? 'border-b-4 border-red text-black' : 'text-gray-h'}`}
                                >
                                    {completedSteps.has(step.slug) && <Tick/>}
                                    <Typography noWrap component="div" className='pb-6 py-5'>
                                        <p className='text-xs xl:text-base relative' style={{transform:"translateY(0.8rem)"}}>
                                            <small className='absolute bottom-full text-[0.75rem]'>Step {index+1}</small>    
                                            {step.name}
                                        </p>
                                    </Typography>
                                </div>
                            ))}
                    </Toolbar>
                </AppBar>
            )}
            <Drawer
                sx={{
                    position: 'absolute',
                    width: drawerWidth,
                    overflow:"hidden",
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        overflow:"hidden",
                        boxSizing: 'border-box',
                        background: (()=>{
                            if(down)
                                return `url(/img/bg-main-desk.jpg) 50% 60% / 60vh`
                            return `url(/img/bg-main-desk.jpg) 50% 50% / 60vh`
                        })(),
                        borderTop: '5px solid #FF003E',
                        transition:"all 1s",
                        backgroundSize:"60vh",
                    },
                }}
                variant="permanent"
                anchor="left"
                className='relative hidden lg:block'>
                <div style={{
                    position:"absolute",
                    top:(()=>{
                        if(up)return "100px";
                        if(down)return "0px";
                        return "100px"
                    })(),
                    transition:"top 1s",
                    left:"0px",
                    width:"100%",
                    height:"100%",
                    backgroundImage:"linear-gradient(transparent,#00000095)"
                }}/>
                <Toolbar />
                <img src='/img/logo-main.png' className='relative z-10' style={{ width: '92%', margin: (()=>{
                    if(up)return "10px auto";
                    if(down)return "20px auto";
                    return "10px auto"
                })(),transition:"margin 1s" }} alt="Logo" />
                <div className='flex gap-3 items-center ml-5 relative z-10'>
                    <a className='flex gap-3 items-center' href={process.env.REACT_APP_APP_URL}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FF003E" className="w-5 h-5 hover:fill-green cursor-pointer">
                            <path fill-rule="evenodd" d="M17 10a.75.75 0 0 1-.75.75H5.612l4.158 3.96a.75.75 0 1 1-1.04 1.08l-5.5-5.25a.75.75 0 0 1 0-1.08l5.5-5.25a.75.75 0 1 1 1.04 1.08L5.612 9.25H16.25A.75.75 0 0 1 17 10Z" clip-rule="evenodd" />
                        </svg>
                        <p className='text-white font-semibold text-xs cursor-pointer'>Back to Home</p>
                    </a>
                </div>
            </Drawer>

        </Box>
    );
}
