import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

export default function InitGoogleAnalytics() {
  const measurementId: string = String(process.env.REACT_APP_GA_MEASUREMENT_ID);
  //initialize
  ReactGA.initialize(measurementId);
}

export function RouteContainer({ title, children }: any) {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = process.env.REACT_APP_NAME + (title ? " - " + title : "");
  }, [navigate]);
  return <>{children}</>;
}
