import "./App.css";

import React, { ReactElement } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Container from "./components/container";

import PersonalDetails from "./pages/personal-details";
import Welcome from "./pages/welcome";
import ChooseCover from "./pages/choose-cover";
import ApplianceDetails from "./pages/appliance-details";
import PaymentDetails from "./pages/payment-details";
import DebitOrderMandate from "./pages/debit-order-mandate";
import { FormStateProvider } from "./components/Context/FormState";
import Application from "./pages/application";
import Complete from "./pages/complete";
import Error from "./pages/error";
import Declined from "./pages/declined";
import Generate from "./pages/generate";
import TermsAndConditions from "./pages/terms-and-conditions";

import InitGoogleAnalytics, { RouteContainer } from "./modules/analytics";

interface PageRoute {
  0: string | null;
  1: string; 
  2: ReactElement; 
}
//array of routes;
const pageRoutes: Array<PageRoute> = [
  ["", "/", <Navigate to={"/generate"} />],
  [null, "/application/:applicationId", <Application />],
  ["Welcome", "/welcome", <Welcome />],
  ["Personal details", "/personal-details", <PersonalDetails />],
  //["Choose cover", "/choose-cover", <ChooseCover />],
  [
    "Application Details",
    "/appliance-details",
    <ApplianceDetails appliances={[]} />,
  ],
  ["Payment details", "/payment-details", <PaymentDetails />],
  ["Payment details", "/debit-order-mandate", <DebitOrderMandate />],
  ["Congrats! Your covered!", "/complete", <Complete />],
  ["Error", "/error", <Error />],
  ["Declined", "/declined", <Declined />],
  ["Generate", "/generate", <Generate />],
  ["Terms and conditions", "/terms-and-conditions", <TermsAndConditions />],
];

function App() {
  InitGoogleAnalytics();
  return (
    <React.Fragment>
      <Router>
        <FormStateProvider>
          <Container>
            <Routes>
              {pageRoutes.map((route: PageRoute, key) => {
                const [title, path, element]: any = route;
                return (
                  <Route
                    key={key}
                    path={path}
                    element={
                      <RouteContainer title={title}>{element}</RouteContainer>
                    }
                  />
                );
              })}
            </Routes>
          </Container>
        </FormStateProvider>
      </Router>
    </React.Fragment>
  );
}

export default App;
