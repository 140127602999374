import React, { ChangeEvent, useState } from 'react';
import moment from 'moment';

interface Props {
    name: string;
    value: string;
    handleChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const DatePicker = ({ name, value, handleChange }: Props) => {
    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');

    const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        const field = name.split('.')[1];
        let newDate;

        switch (field) {
            case 'day':
                newDate = `${year}-${month}-${value}`;
                break;
            case 'month':
                newDate = `${year}-${value}-${day}`;
                break;
            case 'year':
                newDate = `${value}-${month}-${day}`;
                break;
            default:
                newDate = '';
        }

        const changeEvent = {
            target: {
                name: name,
                value: newDate,
            },
        } as ChangeEvent<HTMLSelectElement>;

        handleChange(changeEvent);
    };

    const getDays = () => {
        const mom = moment();
        if (month && month !== '') {
            mom.set('month', Number(month))
        } else {
            mom.set('month', 1);
        }
        const days = mom.daysInMonth();
        return new Array(days).fill('');
    }

    return (
        <div className='flex space-x-2'>
            <select
                name={`${name}.day`}
                value={day}
                onChange={handleSelectChange}
                className='w-full h-12 p-1 bg-white-fbg border-b-2 border-gray-t'>
                <option value=''>DD</option>
                {getDays().map((item, idx) => <option value={String(idx + 1)}>{String(idx + 1)}</option>)}
            </select>
            <select
                name={`${name}.month`}
                value={month}
                onChange={handleSelectChange}
                className='w-full h-12 p-1 bg-white-fbg border-b-2 border-gray-t'>
                <option value=''>MM</option>
                {new Array(12).fill('').map((item, idx) => <option value={String(idx + 1)}>{String(idx + 1)}</option>)}
            </select>
            <select
                name={`${name}.year`}
                value={year}
                onChange={handleSelectChange}
                className='w-full h-12 p-1 bg-white-fbg border-b-2 border-gray-t'>
                <option value=''>YYYY</option>
                <option value='2024'>2024</option>
            </select>
        </div>
    );
};

export default DatePicker;