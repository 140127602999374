import React, { useEffect, useRef, useState } from "react";
import useApplication from "../../hooks/useApplication";
import TextInput from "../../components/Inputs/TextInput";
import { Form, Formik, Field } from 'formik';
import GooglePlacesAutoComplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { easyFixService } from "../../api/services";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';


const PersonalDetails = () => {
    const navigate = useNavigate();
    const formRef = useRef<any>();
    const [id_typeSelected, setid_typeSelected] = useState(false);
    const [titleFocus, setTitleFocus] = useState(false);
    const [id_typeFocus, setid_typeFocus] = useState(false);
    const { application, applicationStep, setApplicationSubmitAction, setApplication, setApplicationStep ,setApplicationLoading, applicationLoading} = useApplication();
    const initialSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        first_name: Yup.string().required('First name is required'),
        last_name: Yup.string().required('Last name is required'),
        id_type: Yup.string().oneOf(['SouthAfricanID', 'Passport']).required('ID type is required'),
        //@ts-ignore
        id_passport_number: Yup.string().required(),
        mobile_number: Yup.string().matches(/^0[678]\d{8}$/, 'Mobile number must be 10 digits').required('Mobile number is required'),
        additional_contact_number: Yup.string().matches(/^0\d{9}$/, 'Home number must be 10 digits').required('Home number is required'),
        email_address: Yup.string().email('Invalid email address').required('Email address is required'),
        address_line_1: Yup.string().required('Street Address is required'),
        shared_address: Yup.boolean().required('Is this a shared address?'),
        accept_ts_cs: Yup.boolean().oneOf([true], 'Please accept the privacy policy').required()
    });
    const [validationSchema, setValidationSchema] = useState(initialSchema);
    const [idLabel, setIdLabel] = useState('ID Number');
    const [idPlaceHolder, setIdPlaceHolder] = useState('9301011234082');

    const handleSubmit = () => {
        formRef.current?.handleSubmit()
    }

    const IdNumberValidation = (idNumber: string) => {
        if (idLabel == 'ID Number') {
            // Remove any spaces from the ID number
            idNumber = idNumber.replace(/\s/g, '');

            // Check if the ID number consists of 13 digits
            if (!/^\d{13}$/.test(idNumber)) {
                return "Invalid SA ID Number";
            }

            // Extract the birthdate from the ID number
            var dob = idNumber.substring(0, 6);
            var year = Number(dob.substring(0, 2));
            var month = Number(dob.substring(2, 4)) - 1; // Month is zero-based
            var day = Number(dob.substring(4, 6));

            // Check if the birthdate is valid
            var birthdate = new Date(1900 + year, month, day);
            if (birthdate.getFullYear() != (1900 + year) || birthdate.getMonth() != month || birthdate.getDate() != day) {
                return "Invalid SA ID Number";
            }

            // Apply the Luhn algorithm to validate the checksum
            var sum = 0;
            for (var i = 0; i < 12; i++) {
                var digit = Number(idNumber.charAt(i));
                if (i % 2 === 0) {
                    sum += digit;
                } else {
                    sum += digit < 5 ? digit * 2 : digit * 2 - 9;
                }
            }
            var checksum = (10 - (sum % 10)) % 10;
            if (checksum !== Number(idNumber.charAt(12))) {
                return "Invalid SA ID Number";
            }
        }
    }

    const getCookie = () => {
        const cookies = document.cookie.split(';');
        const cookieMap: { [key: string]: string } = {}; // Explicitly type cookieMap
        cookies.forEach(cookie => {
            const [name, value] = cookie.trim().split('=');
            cookieMap[name] = value;
        });


        return cookieMap['easyfix_application_id'] || false; // Replace 'cookieName' with the name of your cookie
    };

    const getApplicationStep = async () => {
        const app_id_from_cookie = getCookie();
        let applicationId = '';
        if (!application.application_id && app_id_from_cookie) {
            if (app_id_from_cookie) {
                setApplication({application_id: app_id_from_cookie, step: 1, fields: {}})
                applicationId = app_id_from_cookie;
            }
        } else {
            applicationId = application.application_id;
        }
        const resp = await easyFixService.getStep({application_id: applicationId, step: 1});
        setApplicationStep(resp.data);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if (formRef.current) {
            //@ts-ignore
            setApplicationSubmitAction({ action: () => handleSubmit() })
        }
        if (!applicationStep.step_fields?.field_options['title']) {
            getApplicationStep();
        }
    }, []);

    return (
        <div className='w-full h-full flex flex-col justify-start py-2 px-5 md:py-4 md:px-10 lg:py-0 lg:px-12 2xl:py-12 2xl:px-28'>
            <Formik
                initialValues={{
                    title: '',
                    first_name: '',
                    last_name: '',
                    id_type: '',
                    id_passport_number: '',
                    mobile_number: '',
                    additional_contact_number: '',
                    email_address: '',
                    address_line_1: '',
                    address_line_2: '',
                    suburb: '',
                    city: '',
                    postal_code: '',
                    co_ords: {
                        lat: 0,
                        lng: 0,
                    },
                    shared_address: null,
                    accept_ts_cs: false,
                }}
                //@ts-ignore
                innerRef={(r) => formRef.current = r}
                validationSchema={validationSchema}
                validate={(values) => {
                    const errors = {
                        id_passport_number: ''
                    };
                    // Validate field3 using custom validation function
                    const customError = IdNumberValidation(values.id_passport_number);
                    if (customError) {
                        errors.id_passport_number = customError;
                        return errors;
                    }
                    return {};
                }}
                validateOnBlur={true}
                onSubmit={async (values) => {
                    setApplicationLoading(true);    
                    const resp = await easyFixService.submit({ application_id: application.application_id, step: 1, ...values });
                    if (resp.status === 'success') {
                        const obj = { application_id: application.application_id, step: 2, cover_plan: 'EasyFix_5' };
                        try {
                            const resp = await easyFixService.submit(obj);
                            if (resp.status === 'success') {
                                const resp = await easyFixService.getStep({ application_id: application.application_id, step: 4 });
                                setApplicationStep(resp.data);
                                setApplicationLoading(false);
                                navigate('/payment-details');
                                return;
                            } else {
                                setApplicationLoading(false);
                                navigate('/error')
                                return;
                            }
                        } catch {
                            setApplicationLoading(false);
                            navigate('/error')
                        }
                    }
                }}>
                {(formik: any) => {
                    const { handleChange, values, handleBlur, errors, touched, handleSubmit, setFieldValue } = formik;

                    const handleid_typeChange = (e: any) => {
                        handleChange(e);
                        setid_typeSelected(e.target.value !== '');
                        if(e.target.value == "SouthAfricanID") {
                            setIdLabel('ID Number');
                            setIdPlaceHolder('9301011234082');
                        } else {
                            setIdLabel('Passport Number');
                            setIdPlaceHolder('ABC123');
                            setValidationSchema(initialSchema);
                        }
                    };

                    const getTitleBorderClass = () => {
                        if (errors.title) {
                            return 'border-red';
                        }
                        return titleFocus || values.title ? 'border-green' : 'border-gray-t';
                    };

                    const getFieldBorderClass = (field_id: any, error:any) => {
                        const value = values[field_id];
                        if (value == "" || value == null || value == "0") {
                            return 'border-grey-t';
                        }
                        if (error) {
                            return 'border-red';
                        }
                        return 'border-green';
                    };

                    const getid_typeBorderClass = () => {
                        if (errors.id_type) {
                            return 'border-red';
                        }
                        return id_typeFocus || values.id_type ? 'border-green' : 'border-gray-t';
                    }

                    const handleRadioButton = (value: string) => {
                        setFieldValue('sharedBuilding', value);
                    };

                    const getAddressInfo = async (address: string) => {
                        const geocode = await geocodeByAddress(address);
                            // .then((results) => getLatLng(results[0]))
                            // .then(({ lat, lng }) => {
                            //     return { lat, lng };
                            // });
                        const latLng = await getLatLng(geocode[0]);
                        const [suburb, city, postalCode, province] = getSuburbCity(geocode[0]);
                        handleChange({ target: { name: 'address_line_1', value: address } });
                        handleChange({ target: { name: 'suburb', value: suburb } });
                        handleChange({ target: { name: 'city', value: city } });
                        handleChange({ target: { name: 'postal_code', value: postalCode } });
                        handleChange({ target: { name: 'province', value: province } });
                        handleChange({ target: { name: 'co_ords', value: latLng } });
                    };
                
                    const getSuburbCity = (results: any) => {
                        let suburb = '';
                        let city = '';
                        let postalCode = '';
                        let province = '';
                        results.address_components.forEach((comp: any) => {
                            if (comp.types.includes('postal_code')) postalCode = comp.short_name;
                            if (comp.types.includes('sublocality')) suburb = comp.short_name;
                            if (comp.types.includes('locality')) city = comp.short_name;
                            if (comp.types.includes("administrative_area_level_1")) province = comp.long_name;
                        });
                        return [suburb, city, postalCode, province];
                    };

                    return (
                        <Form className='w-full'>
                            <div className='flex flex-col gap-10 lg:p-10 w-full border-b border-black-b'>
                                <p className='text-2xl xl:text-3xl font-semibold text-gray-h'>Personal details</p>
                                <div className='flex flex-col lg:grid lg:grid-cols-2 gap-10 mb-2'>
                                    <div className='flex flex-col'>
                                        <label className='font-semibold text-xs mb-1' htmlFor='title'>
                                            Title
                                        </label>
                                        <select
                                            id='title'
                                            name='title'
                                            required
                                            value={values.title}
                                            onChange={handleChange}
                                            className={`w-full p-1 bg-white-fbg border-b-2 ${getFieldBorderClass('title', errors.title)} h-12`}>
                                            <option value=''>Select title</option>

                                            {
                                                // @ts-ignore
                                                applicationStep.step_fields?.field_options['title']?.map((item) => <option value={item.name}>{item.name}</option>)
                                            }
                                            {/* <option value='option1'>Mr</option>
                                        <option value='option2'>Mrs.</option>
                                        <option value='option3'>Ms</option> */}
                                        </select>
                                        {errors.title && touched.title && <div className='text-red mt-2'>{errors.title}</div>}
                                    </div>
                                    <TextInput
                                        label={'First name'}
                                        required
                                        onChange={handleChange}
                                        name='first_name'
                                        value={values.first_name}
                                        error={errors.first_name && touched.first_name ? errors.first_name : null}
                                        placeholder='Type first name'
                                        className={`${getFieldBorderClass('first_name', errors.first_name)}`}
                                    />
                                </div>
                                <div className='flex flex-col lg:grid lg:grid-cols-2 gap-10 mb-2'>
                                    <TextInput
                                        label={'Last name'}
                                        required
                                        onChange={handleChange}
                                        name='last_name'
                                        value={values.last_name}
                                        error={errors.last_name && touched.last_name ? errors.last_name : null}
                                        placeholder='Type last name'
                                        className={`${getFieldBorderClass('last_name', errors.last_name)}`}
                                    />
                                    <div className='flex flex-col'>
                                        <label className='font-semibold text-xs mb-1' htmlFor='id_type'>
                                            ID Type
                                        </label>
                                        <Field
                                            as="select"
                                            id='id_type'
                                            name='id_type'
                                            required
                                            onChange={handleid_typeChange}
                                            onBlur={() => setid_typeFocus(false)}
                                            onFocus={() => setid_typeFocus(true)}
                                            className={`w-full p-1 bg-white-fbg border-b-2 ${getFieldBorderClass('id_type', errors.id_type)} h-12`}>
                                            <option value=''>Select ID type</option>
                                            <option value='SouthAfricanID'>South African ID</option>
                                            <option value='Passport'>Passport</option>
                                        </Field>
                                        {errors.id_type && touched.id_type && <div className='text-red mt-2'>{errors.id_type}</div>}
                                    </div>
                                </div>
                                {id_typeSelected && (
                                    <TextInput
                                        label={idLabel}
                                        required
                                        onChange={handleChange}
                                        name='id_passport_number'
                                        value={values.id_passport_number}
                                        error={errors.id_passport_number && touched.id_passport_number ? errors.id_passport_number : null}
                                        placeholder={idPlaceHolder}
                                        className={`w-full ${getFieldBorderClass('id_passport_number', errors.id_passport_number)}`}
                                    />
                                )}
                                {/*{errors.id_passport_number && touched.id_passport_number && <div className='text-red mt-2'>{errors.id_passport_number}</div>}*/}
                            </div>
                            <div className='flex flex-col gap-10 mt-10 lg:mt-0 lg:p-10 w-full border-b border-black-b'>
                                <p className='text-2xl font-semibold text-gray-h'>Contact details</p>
                                <div className='flex flex-col lg:grid lg:grid-cols-2 gap-10 mb-2'>
                                    <TextInput
                                        label={'Cellphone number'}
                                        required
                                        onChange={handleChange}
                                        name='mobile_number'
                                        value={values.mobile_number}
                                        error={errors.mobile_number && touched.mobile_number ? errors.mobile_number : null}
                                        placeholder='0821234567'
                                        className={`${getFieldBorderClass('mobile_number', errors.mobile_number)}`}
                                    />
                                    <TextInput
                                        label={'Email address'}
                                        required
                                        onChange={handleChange}
                                        name='email_address'
                                        value={values.email_address}
                                        error={errors.email_address && touched.email_address ? errors.email_address : null}
                                        placeholder='Type email address'
                                        className={`${getFieldBorderClass('email_address', errors.email_address)}`}
                                    />
                                    <TextInput
                                        label={'Additional contact number'}
                                        required
                                        onChange={handleChange}
                                        name='additional_contact_number'
                                        value={values.additional_contact_number}
                                        error={errors.additional_contact_number && touched.additional_contact_number ? errors.additional_contact_number : null}
                                        placeholder='0821234567'
                                        className={`${getFieldBorderClass('additional_contact_number', errors.additional_contact_number)}`}
                                    />
                                </div>
                            </div>
                            <div className='flex flex-col gap-10 mt-10 lg:mt-0 lg:p-10 w-full'>
                                <p className='text-2xl font-semibold text-gray-h'>Address details</p>
                                <div className='flex items-center gap-10'>
                                    <div className='w-full flex flex-col xl:grid xl:grid-cols-2 gap-10 mb-2'>
                                        <div className="flex flex-col gap-3">
                                            <div className='flex gap-2 items-center self-start'>
                                                <p className='text-xs text-gray-h font-semibold text-left'>Is this address in a shared building or a complex?</p>
                                                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="#00000029" xmlns="http://www.w3.org/2000/svg" color="#00000029" stroke-width="1.5">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12 10.75C12.4142 10.75 12.75 11.0858 12.75 11.5V16.5C12.75 16.9142 12.4142 17.25 12 17.25C11.5858 17.25 11.25 16.9142 11.25 16.5V11.5C11.25 11.0858 11.5858 10.75 12 10.75ZM12.5675 8.00075C12.8446 7.69287 12.8196 7.21865 12.5117 6.94156C12.2038 6.66446 11.7296 6.68942 11.4525 6.99731L11.4425 7.00842C11.1654 7.3163 11.1904 7.79052 11.4983 8.06761C11.8062 8.34471 12.2804 8.31975 12.5575 8.01186L12.5675 8.00075Z"
                                                        fill="#00000029"></path></svg>
                                            </div>
                                            <div className='flex gap-5 items-center justify-start'>
                                                <label className={`bg-white-t rounded-full py-4 flex px-6 gap-4 items-center cursor-pointer ${values.shared_address === true ? 'ring-2 ring-green-500' : ''}`}>
                                                    <input
                                                        className="hidden"
                                                        type="radio"
                                                        id="sharedBuildingYes"
                                                        name="shared_address"
                                                        onChange={(e) => handleChange({ target: { name: 'shared_address', value: true } })}
                                                        checked={values.shared_address === true}
                                                        onBlur={handleBlur}
                                                    />
                                                    <div className={`rounded-full p-2 ${values.shared_address === true ? 'bg-green' : 'bg-white'}`}></div>
                                                    <p className='text-black text-xs font-semibold'>Yes</p>
                                                </label>
                                                <label className={`bg-white-t rounded-full py-4 px-6 flex gap-4 items-center cursor-pointer ${values.shared_address === false ? 'ring-2 ring-green' : ''}`}>
                                                    <input
                                                        className="hidden"
                                                        type="radio"
                                                        id="shared_addressNo"
                                                        name="shared_address"
                                                        onBlur={handleBlur}
                                                        onChange={(e) => handleChange({ target: { name: 'shared_address', value: false } })}
                                                        checked={values.shared_address === false}

                                                    />
                                                    <div className={`rounded-full p-2 ${values.shared_address === false ? 'bg-green' : 'bg-white'}`}></div>
                                                    <p className='text-black text-xs font-semibold'>No</p>
                                                </label>
                                            </div>
                                            {errors.shared_address && touched.shared_address ? <p className="text-red">{errors.shared_address}</p> : null}
                                        </div>
                                        {values.shared_address === true && (
                                            <div className='w-full'>
                                                <TextInput
                                                    label={'Building / complex name and unit number'}
                                                    required
                                                    onChange={handleChange}
                                                    name='address_line_2'
                                                    value={values.address_line_2}
                                                    error={errors.address_line_2}
                                                    placeholder='The Willows, Unit 23'
                                                />
                                            </div>
                                        )}
                                        <div className="flex flex-col gap-2 w-full">
                                            <p className='text-xs text-gray-h font-semibold text-left'>Street Address</p>
                                            <GooglePlacesAutoComplete
                                                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                                                apiOptions={{ region: 'za' }}
                                                autocompletionRequest={{
                                                    types: ['address'],
                                                    componentRestrictions: {
                                                        country: ['za'],
                                                    },
                                                }}
                                                selectProps={{
                                                    placeholder: 'Start by typing your address...',
                                                    onChange: (e: any) => { getAddressInfo(e?.label) },
                                                    name: 'address_line_1',
                                                    className: 'mt-1 w-full',
                                                    blurInputOnSelect: true,
                                                    // styles: customStyles,
                                                }}
                                            />
                                            {errors.address_line_1 && touched.address_line_1 ? <p className="text-red">{errors.address_line_1}</p> : null}
                                        </div>
                                    </div>

                                </div><div className='border-b border-black-b' />
                                <div className='flex flex-col gap-5 w-full'>
                                    <p className='text-2xl font-semibold text-gray-h'>Privacy Policy</p>
                                    <p className='text-sm text-black'>By clicking ‘I accept’ you acknowledge and accept Teljoy's
                                        <a href={'https://www.teljoy.co.za/privacy-policy'} target={'_blank'} className='text-indigo underline font-semibold ml-1'>privacy policy.</a></p>
                                    <div className='flex items-center mt-4 bg-white-t rounded-3xl p-4 w-fit'>
                                        <Field type="checkbox" id={'acceptPrivacy'} name="accept_ts_cs" value={values.accept_ts_cs} checked={values.accept_ts_cs} onChange={(e: any) => handleChange({ target: { name: e.target.name, value: e.target.checked ? true : false } })} />
                                        <label htmlFor="acceptPrivacy" className='ml-2 text-black font-semibold text-xs'>
                                            I accept the privacy policy
                                        </label>
                                    </div>
                                    {errors.accept_ts_cs && touched.accept_ts_cs ? <p className="text-red">{errors.accept_ts_cs}</p> : null}
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default PersonalDetails;
