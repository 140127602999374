import React, { useEffect } from "react";
import useApplication from "../../hooks/useApplication";
import { useNavigate } from "react-router";

const Welcome = () => {
    const navigate = useNavigate();
    const { application, setApplicationSubmitAction, setApplicationStep, setApplicationLoading, applicationLoading } = useApplication();
    const goToNextStep = async () => {
        navigate('/welcome');
    };
    
    useEffect(() => {
        setApplicationSubmitAction({ action: goToNextStep })
    }, []);
    return (
        <div className='w-full h-full flex flex-col justify-start py-2 px-5 md:py-4 md:px-10 lg:py-10 lg:px-14 xl:py-12 xl:px-28'>
            <div className="flex flex-col gap-10 w-full border-b border-black-b pb-8 tandc">
                <h1 className="font-semibold text-2xl xl:text-3xl">Terms and Conditions</h1>
                <h3>SERVICE AGREEMENT</h3>
                <p>Easyfix will provide Service for the Appliance/s described herein, in accordance with the
                    terms and conditions set out on the pages subsequent to this page. The Customer acknowledges
                    that the application information that he / she provided to Easyfix and which is stored
                    electronically is true and correct.
                </p>
                <h3 id="section_3_1">TERMS AND CONDITIONS OF SERVICE</h3>
                <ol className={'list-decimal'}>
                    <li>
                        <h3>Definitions</h3>
                        <ol className={'list-decimal ml-6'}>
                            <li>“Ad hoc Fees” means Fees due by the Customer to Easyfix arising from repairs
                                performed at the Customer’s request that fall outside of the ambit of Easyfix’s
                                Service obligations and any other Fees or charges due in terms of this
                                Agreement.
                            </li>
                            <li>“Agreement” means the agreement between Easyfix and the Customer for the Service
                                by Easyfix of one or more Appliances belonging to the Customer, which agreement
                                shall be governed by these terms and conditions as amended from time to time.
                            </li>
                            <li>“Annual Maximum Limit” means the number of repairs per Appliance per annum that
                                Easyfix is required to affect, which limit is currently set at 2 (two) repairs
                                per annum.
                            </li>
                            <li>“Appliance Maximum Repair Value Limit” means the Repair Value per Appliance set
                                by Easyfix from time to time that represents the maximum amount that Easyfix
                                will be obliged to expend on an Appliance per call-out.
                            </li>
                            <li>“Appliance/s” means a consumer electronic appliance nominated by a Customer for
                                Service Cover in terms of this Agreement. This excludes ICT Appliances.
                            </li>
                            <li>“Audio-visual Appliances” means appliances generally used by consumers for
                                audio-visual entertainment, such as televisions, decoders, home theatres and
                                smart televisions, but excludes any item upon which the Customer can load
                                software.
                            </li>
                            <li>“Beyond Economical Repair” means an Appliance that Easyfix believes will cost
                                more to repair than the market value of the Appliance.
                            </li>
                            <li>“Customer” means the person who’s Appliance Easyfix has agreed to maintain on
                                the terms and conditions set out in this Agreement.
                            </li>
                            <li>
                                “EasyFix” or ‘Teljoy’ or ‘Teljoy Easyfix’ means Film Fun Holdings (Pty) Ltd and
                                its successors in title and assigns; whereby all post-sale services will be performed by
                                Teljoy Easyfix.
                            </li>
                            <li>“Fee/s” or “Service Fee/s” means the fee payable by the Customer to Easyfix in
                                consideration for Easyfix providing Services to the Customer, which fee
                                may be per Appliance or for a number of Appliances and may be payable monthly or
                                for a specific period.
                            </li>
                            <li>“ICT Appliances” means appliances generally used by consumers in the processing
                                of data or communication, such as laptops, personal computers, tablet devices
                                and smart phones. ICT Appliances are not covered under this maintenance contract.
                            </li>
                            <li>“In-home Service” means the despatch of a technician to the Customer’s home to
                                repair or evaluate the repair of an Appliance and / or the removal of the
                                Appliance from the Customer’s home for the purpose of repairing the Appliance.
                            </li>
                            <li>“Kitchen Appliances” means appliances generally found in consumers’ kitchens
                                such as refrigerators, stoves, washing machines, tumble dryers and microwave
                                ovens.
                            </li>
                            <li>“Repair Value” means the commercial value of a repair and shall be calculated
                                with reference to Easyfix’s standard labour rates from time to time (currently
                                R500 (five hundred Rand)) per hour and the list price of any parts and
                                consumables required for a repair.
                            </li>
                            <li>“Schedule” means a print-out from Easyfix’s computer systems from time to time
                                upon which the details of the Customer, the Appliance/s and the fees payable by
                                the Customer to Easyfix are described.
                            </li>
                            <li>“Service” means the performance of the necessary repairs to restore the
                                Appliance/s to working order during the currency of this Agreement and
                                “Maintain” shall have a similar meaning.
                            </li>
                            <li>“Service Agent” means a sub-contractor to Easyfix that Easyfix has contracted to
                                collect, deliver or repair an Appliance.
                            </li>
                            <li>“Service Area” means the area within a fifty (50) kilometre radius of each of
                                Easyfix’s service agents from time to time.
                            </li>
                            <li>“Service Product/s” means a matrix of maintenance services and bundles that
                                Easyfix may offer from time to time permitting the Customer to nominate one or
                                more Appliances to be maintained by Easyfix in consideration for the
                                corresponding Fees.
                            </li>
                            <li>“Technical Failure” means a failure of a mechanical part of the Appliance due to
                                latent defect or as a result of normal wear and tear.
                            </li>
                            <li> “Waiting Period” means a period of three months from the inception of this
                                Agreement or the addition of any new Appliance, during which Easyfix shall not
                                be obliged to render Service Services other than telephonic support.
                                This waiting period may at Teljoy’s discretion be waived from time to time.
                            </li>
                            <li>“Year” means twelve calendar months from the beginning of the month in which the
                                Service Agreement is signed, and then each subsequent period of twelve calendar
                                months.
                            </li>
                        </ol>
                    </li>

                    <li id="section_3_2">
                        <h3> Service Products</h3>
                        <ol className={'list-decimal ml-6'}>
                            <li>Appliance Service
                                <ol className={'list-decimal ml-6'}>
                                    <li>The Customer may nominate a number of Appliances for Service against
                                        payment of the agreed aggregate Fee for the type, size and number of the
                                        Appliances.
                                    </li>
                                </ol>
                            </li>
                            <li>Additional Options
                                <ol className={'list-decimal ml-6'}>
                                    <li>Easyfix may, from time to time, add additional ancillary services to its
                                        Service Product offering and these terms and conditions will apply,
                                        mutatis mutandis.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>

                    <li id="section_3_3">
                        <h3> Duration</h3>
                        <ol className={'list-decimal ml-6'}>
                            <li>The Teljoy Service Product offering may provide the Customer with different options with
                                regard to the duration of this Agreement, including but not limited to:
                                <ol className={'list-decimal ml-6'}>
                                    <li>A monthly service offering whereby the agreement continues indefinitely subject
                                        to either Teljoy or the Customer terminating on one calendar months’ notice.
                                    </li>
                                </ol>
                            </li>
                            <li>Notwithstanding anything to the contrary, Teljoy may give one calendar months’ notice of
                                termination to the Customer at any time.
                            </li>
                        </ol>
                    </li>

                    <li id="section_3_4">
                        <h3>Service</h3>
                        <ol className={'list-decimal ml-6'}>
                            <li>Easyfix hereby agrees to maintain the Appliance/s for the duration of this
                                Agreement, subject to these terms and conditions and in particular the
                                exclusions and exceptions set out therein.
                            </li>
                            <li>To this end, Easyfix will provide, at no additional cost to the Customer, labour
                                and parts which Easyfix, in its sole discretion considers necessary to restore
                                the appliance to working order following a Technical Failure to the maximum of
                                the:
                                <ol className={'list-decimal ml-6'}>
                                    <li>Appliance Maximum Repair Value Limit, and</li>
                                    <li>Annual Maximum Limit.</li>
                                </ol>
                            </li>
                            <li>Easyfix’s obligation to Maintain the Appliance will be suspended for any period
                                during which the Customer has not paid the requisite monthly or annual
                                maintenance Fee/s.
                            </li>
                            <li>The Customer agrees to a three-month Waiting Period where applicable before Easyfix is
                                required
                                to provide Service Services on an Appliance.
                            </li>
                            <li>Easyfix will, however, provide telephonic support and assistance with
                                manufacturers’ warranties during the first three months of this Agreement.
                            </li>
                        </ol>
                    </li>

                    <li id="section_3_5">
                        <h3>Fees &amp; Payment</h3>
                        <ol className={'list-decimal ml-6'}>
                            <li>The first monthly Service Fee is payable in advance, with all subsequent monthly
                                Service Fees being payable, in advance, on or before the first day of the
                                following and subsequent calendar months.
                            </li>
                            <li>Easyfix may increase the monthly Service Fee on an annual basis by the greater
                                of the Consumer Price Index for the previous year and ten percent, either on the
                                anniversary of this Agreement or on a particular date each year.
                            </li>
                            <li>Annual Fees are payable annually in advance.</li>
                            <li>Fixed period Service Fees are payable in full in advance of the agreed period.
                            </li>
                            <li>All payments due by the Customer to Easyfix shall be made, without deduction or
                                set-off by means of a debit order on the Customer’s bank account.
                            </li>
                            <li>If the Customer changes banks, Easyfix cedes this agreement or banking
                                regulations change, the Customer agrees to sign a new debit order mandate.
                            </li>
                            <li>Payment will only have been made if the debit order is not returned, or in the
                                event of a cheque or EFT, once the cheque or EFT has been honoured.
                            </li>
                            <li>The computer accounts of a certificate signed by the financial manager,
                                subscriber manager or any director of Easyfix, whose appointment need not be
                                proved, will be prima facie proof of all monies due by the Customer to Easyfix.
                            </li>
                            <li>All amounts due to Easyfix will bear value added tax at the applicable rate from
                                time to time.
                            </li>
                        </ol>
                    </li>

                    <li id="section_3_6">
                        <h3>Administration and Ad-hoc Fees</h3>
                        <ol className={'list-decimal ml-6'}>
                            <li>Easyfix shall be entitled to debit the Customer’s account with the following
                                administration Fees:
                                <ol className={'list-decimal ml-6'}>
                                    <li>A fee of R500 (five hundred Rand) where access is denied to the Easyfix
                                        Service Agent notwithstanding that a time period for the in-home service
                                        has been arranged.
                                    </li>
                                    <li>A fee of R100 (One-hundred) on any returned debit order, dishonoured
                                        cheque or the like.
                                    </li>
                                </ol>
                            </li>
                            <li>Where the repair falls outside of Easyfix’s obligations in terms of this
                                Agreement and the Customer requests Easyfix to attend to the repair anyway,
                                Easyfix shall, in addition to any other fees payable, be entitled to debit the
                                Customer’s account with the agreed cost of the repair quoted by Easyfix and
                                accepted by the Customer.
                            </li>
                        </ol>
                    </li>

                    <li id="section_3_7">
                        <h3>General Extensions and Exclusions</h3>
                        <ol className={'list-decimal ml-6'}>
                            <li>The extensions and exclusions set out in this clause apply to all Appliances,
                                regardless of the product category into which they fall.
                            </li>
                            <li>Easyfix’s Service obligation includes:-
                                <ol className={'list-decimal ml-6'}>
                                    <li>Email and telephonic access to Easyfix’s technical service call centre
                                        during office hours.
                                    </li>
                                    <li>Telephonic support and fault diagnosis.</li>
                                    <li>In-Home Service unless specified otherwise in the relevant product
                                        category or where the Customer’s home is outside of the Service Area.
                                    </li>
                                    <li>Physical repairs to the Appliance to correct a Technical Failure of the
                                        Appliance.
                                    </li>
                                    <li>Re-connection of any Appliance disconnected or removed for repair.</li>
                                    <li>A maximum of two (2) repairs per Appliance per annum, calculated from
                                        the inception of this Agreement.
                                    </li>
                                    <li>A Maximum Repair Value per event.</li>
                                </ol>
                            </li>
                            <li>Technical Failure excludes, inter alia, the following:-
                                <ol className={'list-decimal ml-6'}>
                                    <li>Any use of the Appliance for a purpose or duration for which it is not
                                        commonly intended to be used.
                                    </li>
                                    <li>Any use of the Appliance for commercial or business purposes.</li>
                                    <li>Any accident to, neglect, abuse or misuse of the Appliance, including
                                        missing parts or defective discs or other media.
                                    </li>
                                    <li>Moisture, water damage, corrosion, rust, or other environmental
                                        factors.
                                    </li>
                                    <li>Rodents, insects or other pestilence.</li>
                                    <li>Acts of God, including lightning.</li>
                                    <li>Electrical surge.</li>
                                    <li>Tampering with or repair by any non-Easyfix approved person.</li>
                                </ol>
                            </li>
                            <li>Easyfix’s Service obligation excludes: –
                                <ol className={'list-decimal ml-6'}>
                                    <li>The electricity supply to the Appliance, including the wiring from the
                                        plug socket to the Appliance.
                                    </li>
                                    <li>Cosmetic parts, such as the exterior of the Appliance or any cabinet
                                        containing the Appliance.
                                    </li>
                                    <li>Speakers, amplifiers, sub-woofers, clocks, pilot lights.</li>
                                    <li>Batteries globes, bulbs, filters or other consumable items.</li>
                                    <li>Any ancillary device connected to the Appliance such as a decoder,
                                        personal computer, games console, home theatre, external speakers,
                                        external hard drives etc.
                                    </li>
                                    <li>Routine cleaning of any moving parts such as DVD lasers or filters on
                                        dishwashers, tumble dryers and washing machines.
                                    </li>
                                    <li>Hand-held remote controls.</li>
                                    <li>Tuning or re-tuning of any appliance or ancillary appliance unless
                                        required as part of a repair.
                                    </li>
                                    <li>After-hours call-outs.</li>
                                    <li>Any interaction with Multichoice, Cellular Service Provider, or any
                                        other provider of content with regard to the connection of smart cards
                                        or account queries.
                                    </li>
                                    <li>The repair of any Appliance that is still under a manufacturer’s
                                        warranty or within ninety days after the manufacturer has affected a
                                        repair. Easyfix will, however, liaise with the manufacturer to assist
                                        the Customer in this regard.
                                    </li>
                                    <li>The repair of any Appliance subject to a manufacture or distributor
                                        recall.
                                    </li>
                                    <li> The repair of any Appliance not correctly listed in the Schedule.</li>
                                    <li>Defects that pre-date the inception of the Service Agreement that were
                                        known or should reasonably have been known to the Customer.
                                    </li>
                                </ol>
                            </li>
                            <li>The Customer agrees that Easyfix shall not be required to keep any parts removed
                                from any Appliance separate, nor to provide any such parts to the Customer.
                            </li>
                            <li>If the Customer disputes whether a repair falls within one of the exclusions
                                listed in this Agreement, then the dispute shall be referred to a qualified
                                technician nominated by Easyfix, who shall act as an expert and whose decision
                                shall be final and binding on Easyfix and the Customer.
                            </li>
                            <li>For the avoidance of doubt, Easyfix will not consider the following categories
                                of Appliances:
                                <ol className={'list-decimal ml-6'}>
                                    <li>Appliances used for commercial purposes e.g. Pubs, conferences,
                                        advertising, cooking schools etc.
                                    </li>
                                    <li>Rear-screen projection televisions and other outmoded technology.</li>
                                    <li>Appliances that are more than eight years old (any appliance that is
                                        more than eight years old will automatically no longer form a part of
                                        this Agreement).
                                    </li>
                                    <li>Appliances that are “grey” or parallel imports and appliances that are
                                        not supported by the local representatives of imported appliances.
                                    </li>
                                    <li>Appliances situated outside the borders of the Republic of South
                                        Africa.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>

                    <li id="section_3_8">
                        <h3>Specific Exclusions</h3>
                        <ol className={'list-decimal ml-6'}>
                            <li>The exclusions set out in this clause apply to all relevant Appliances and
                                Appliance Categories, but are listed here under particular categories for ease
                                of reference.
                            </li>
                            <li id="section_3_8_1">Audio-visual Appliances – Service Excludes:-
                                <ol className={'list-decimal ml-6'}>
                                    <li>Service of any antennae, aerial, satellite dish, LNB or cabling to the
                                        Appliance.
                                    </li>
                                    <li>Any tapes, DVD’s or data stored on any hard drive or storage medium of
                                        any kind, it being acknowledged that repairs to hard drives / swap-outs
                                        of PVR devices are likely to result in recorded data and programmes
                                        being deleted.
                                    </li>
                                </ol>
                            </li>
                            <li>Kitchen Appliances – Service Excludes: –
                                <ol className={'list-decimal ml-6'}>
                                    <li>Plumbing required between the water supply of the home and the
                                        Appliance.
                                    </li>
                                    <li>Gas leaks in refrigerators or freezers, which in the opinion of Easyfix
                                        cannot be repaired. Light bulbs, glass shelves or icemakers in a
                                        refrigerator.
                                    </li>
                                    <li>Damage caused by foreign objects, blocked filters, pumps and drains.
                                    </li>
                                    <li>Damage caused by freezing.</li>
                                </ol>
                            </li>
                            <li>ICT Appliances – All Service of ICT Equipment is excluded for the purposes of this
                                agreement
                            </li>
                            <li>Satellite Decoders – Service Excludes:
                                <ol className={'list-decimal ml-6'}>
                                    <li>Problems related to MultiChoice data download.
                                    </li>
                                    <li>Connectivity due to bouquet changes done by MultiChoice
                                    </li>
                                    <li>TV points other than 1 point for single decoders or 2 points for XtraView.
                                    </li>
                                    <li>Remotes and batteries.
                                    </li>
                                    <li>TV links and remote blasters.
                                    </li>
                                    <li>Loss of recordings or data due to repairs or exchange of decoders.
                                    </li>
                                    <li>Installations not complying with MultiChoice standards (example underground
                                        cables).
                                    </li>
                                    <li>Double storey houses where the dish is not accessible via a normal 3-meter
                                        ladder.
                                    </li>
                                    <li>Communal dish and installations.
                                    </li>
                                    <li>Service other than the satellite feed connection (example Netflix, Showmax or
                                        any internet services).
                                    </li>
                                    <li>Accessories like Routers etc.
                                    </li>
                                    <li>Relocation of satellite dish due to obstructions etc. trees.
                                    </li>
                                    <li>Insurance type claims (Lightning, Fire, Storm and Theft).
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>

                    <li id="section_3_9">
                        <h3>Service Levels</h3>
                        <ol className={'list-decimal ml-6'}>
                            <li>The Customer is required to request maintenance (no later than 30 days after the
                                fault becomes evident) by calling Easyfix’s call-centre during office hours and
                                providing the call centre agent with, inter alia, the following details:
                                <ol className={'list-decimal ml-6'}>
                                    <li>The Service Contract number.</li>
                                    <li>Updated contact numbers and confirmation of physical address.</li>
                                    <li>The make and model of the Appliance that requires repair.</li>
                                    <li>A full description of the reason that the Appliance requires repair.
                                    </li>
                                </ol>
                            </li>
                            <li>Teljoy will endeavour to respond to a request for maintenance within a reasonable time
                                of the request being received,
                                which shall depend on the nature of the request, type of appliance, time of day that
                                that request was received,
                                service area and availability of appropriate Service Agent / Technician.
                            </li>
                            <li>Where the Appliance is located outside of Easyfix’s Service Areas from time to
                                time, In-home Service may not apply and Service may instead be provided on a
                                “Carry-in” basis.
                            </li>
                            <li>Teljoy will endeavour to repair the Appliance within a reasonable time after
                                the initial response, but the repair time is dependent on the availability of
                                spare parts.
                            </li>
                            <li>Teljoy will endeavour to assess and repair the Appliance as far as reasonably possible,
                                however Teljoy shall not be obliged to proceed with a repair
                                in respect of which the Customer has requested Teljoy to repair where the
                                necessary spare parts are not available or where, in the reasonable opinion of
                                Teljoy, it is Beyond Economical Repair. In this event, Teljoy shall be
                                entitled, but not obliged to: –
                                <ol className={'list-decimal ml-6'}>
                                    <li>Declare the Appliance to be Beyond Economical Repair, and</li>
                                    <li>Terminate Teljoy's obligations in respect of that Appliance and the
                                        Customer’s obligation to pay Service Fees in respect of that Appliance
                                        (on a pro-rate basis in the case of a multiple Appliance product), and
                                    </li>
                                    <li>Compensate the Customer by paying to the Customer an amount equal to the
                                        lesser of the replacement value of the Appliance or six times the
                                        monthly premiums paid by the Customer on that Appliance.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>

                    <li id="section_3_10">
                        <h3>Breach</h3>
                        <ol className={'list-decimal ml-6'}>
                            <li>Without prejudice to any other claims and remedies that Easyfix may have against
                                the Customer, Easyfix may cancel and terminate this agreement with immediate
                                effect if the Customer breaches any term of this agreement and remains in breach
                                for a period of ten days from the date upon which Easyfix notified the Customer.
                            </li>
                            <li>Notification shall mean the dispatch by Easyfix of a notification, whether in
                                the form of an email, SMS, telephone call or otherwise to such an address
                                provided by the Customer to Easyfix from time to time.
                            </li>
                        </ol>
                    </li>

                    <li id="section_3_11">
                        <h3>General</h3>
                        <ol className={'list-decimal ml-6'}>
                            <li>The Customer is responsible for the procurement of all licenses or other
                                compliance with any procedures required to use the Appliance, including but not
                                limited to television licenses required under the Broadcasting Act and
                                compliance with RICA.
                            </li>
                            <li>Due to the difficulty of estimating repair times and traffic conditions, it is
                                not possible to give accurate estimates for response, repair or appointments.
                                Accordingly, all times referred to in this agreement, including but not limited
                                to response times, repair times and appointments shall be approximate estimates
                                and the Customer agrees not to hold Easyfix liable for or to claim for any
                                losses, particularly of a consequential or pure economic nature.
                            </li>
                            <li>If the Customer is not a Consumer as defined in the Consumer Protection Act,
                                then all risk in and to the Appliance from damage, loss or theft shall remain
                                vested in the Customer for the duration of this agreement.
                            </li>
                            <li>If the Customer is a Consumer as defined in the Consumer Protection Act, then
                                all risk in and to the Appliance from damage, loss or theft shall remain vested
                                in the Customer for the duration of this agreement unless such loss, damage or
                                theft is proved to have arisen from the gross negligence of Easyfix or its
                                appointed Service Agent.
                            </li>
                            <li>This Agreement, the Schedule and any appendices hereto, constitute the sole
                                record of the agreement between Easyfix and the Customer with regard to the
                                appliance, and Easyfix shall not be bound by any representation, warranty, or
                                implied term not recorded herein.
                            </li>
                            <li>No addition to, variation or consensual cancellation of this agreement shall be
                                of any force and effect unless reduced to writing and signed on behalf of
                                Easyfix. No indulgence which Easyfix may grant to a Customer shall constitute a
                                waiver of any of Easyfix’s rights.
                            </li>
                            <li>Easyfix may, without notice, cede and assign its rights and obligations under
                                this agreement, either absolutely or as collateral security to any person or
                                entity whereupon the Customer shall hold the Equipment on behalf of such person
                                instead of Easyfix and, if required, make the maintenance payments to that
                                person or entity.
                            </li>
                            <li>The Customer may not cede or assign his rights in terms of this agreement
                                without the prior written consent of Easyfix.
                            </li>
                            <li>The Customer hereby indemnifies Easyfix against any loss or damage suffered by
                                Easyfix arising out of or in connection with the Customer’s use of the
                                Appliance/s from whatever cause and against any claims of whatever nature that
                                may be instituted against Easyfix arising from the use of the Appliance/s and
                                any consequential loss or damage arising from the foregoing.
                            </li>
                            <li>The customer recognizes that Easyfix is entitled to assess its existence,
                                details and propensity to pay and accordingly consents to Easyfix accessing
                                relevant databases for the purposes of assessing this and to Easyfix providing
                                information to such databases on the Customer’s propensity to pay monies due to
                                Easyfix.
                            </li>
                            <li>Easyfix shall be entitled to amend this Service Agreement by giving 30 (thirty)
                                days’ notice by email or post to the Customer’s last known address.
                            </li>

                        </ol>
                    </li>

                    <li id="section_3_12">
                        <h3 id="section_3_12" className="">Advertising disclaimer for Easyfix</h3>
                        <ol className={'list-decimal ml-6'}>
                            <li>All prices in South African Rands.</li>
                            <li>E&amp;EO.</li>
                            <li>Promotional pricing is not valid for already discounted deals.</li>
                            <li>Limited service areas apply.</li>
                            <li>Banking details are required to apply.</li>
                            <li>Free gifts are not covered as part of the Easyfix agreement and therefore will
                                not
                                enjoy the same benefits such as risk, maintenance or the ability to upgrade the
                                product.
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>
        </div>
    );
}
    ;

    export default Welcome;
