import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useRef, useState, } from "react";
import TextInput from "../../components/Inputs/TextInput";
import TextArea from "../../components/Inputs/TextArea";
import DatePicker from "../../components/DatePicker";
import useApplication from "../../hooks/useApplication";
import { easyFixService } from "../../api/services";
import { useNavigate } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, IconButton } from "@material-ui/core";
import { ChevronRight } from "@mui/icons-material";

interface Appliance {
    easy_fix_appliance_major_group_id: number;
    easy_fix_appliance_makes_id: number;
    product_description: string;
    date_purchased: string;
    serial_number: string;
}

interface FormState {
    appliances: Appliance[];
}

const ApplianceDetails = ({ appliances }: FormState) => {
    const navigate = useNavigate();
    const [idTypeSelected, setIdTypeSelected] = useState(false);
    const [titleFocus, setTitleFocus] = useState(false);
    const [idTypeFocus, setIdTypeFocus] = useState(false);
    const [editModeIndices, setEditModeIndices] = useState<Record<number, boolean>>({});
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [submittedValues, setSubmittedValues] = useState([]);
    const formRef = useRef();
    const { application, applicationStep, setApplicationStep, applicationValues, setApplicationValues, setApplicationSubmitAction } = useApplication();
    const [expanded, setExpanded] = useState(-1);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (formRef.current) {
            // @ts-ignore
            setApplicationSubmitAction({ action: formRef.current?.handleSubmit })
        }
    }, []);

    const handleSubmit = (values: any) => {
        setFormSubmitted(true);
        setSubmittedValues(values.appliances);
    };

    const toggleEditMode = (index: number) => {
        setEditModeIndices((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };


    const removeAppliance = (arrayHelpers: any, index: number) => {
        arrayHelpers.remove(index);
        setEditModeIndices(prevState => {
            const newState = { ...prevState };
            delete newState[index];
            return newState;
        });
    };

    return (
        <div className='w-full h-full flex flex-col justify-start py-2 px-5 mb-10 lg:mb-0 md:py-4 md:px-10 lg:py-0 lg:px-12 2xl:py-12 2xl:px-28 relative'>
            <div className='flex flex-col gap-10 lg:p-9 xl:p-10 w-full'>
                <div className='flex flex-col gap-4'>
                    <p className='text-2xl xl:text-3xl font-semibold text-gray-h'>Appliances covered</p>
                    <p className='text-black text-sm'>The EasyFix Maintenance Plan covers a wide range of household appliances not older than 8 years,
                        including: Blu-ray systems, convectional ovens, dishwashers, DVD players, microwave ovens, refrigeration
                        units such as general refrigerators, freezers and bar fridges, sound systems, stoves, television sets, satellite
                        decoders, tumble dryers, and washing machines. <span className='font-semibold'>(This is an optional step, you can skip now and specify
                            appliances when you claim)</span></p>
                </div>
            </div>
            <div className='flex flex-col gap-10 w-full mt-10 lg:mt-0 lg:p-10'>
                <h2 className='text-lg xl:text-2xl font-semibold text-gray-h text-left'>Appliance details (Optional)</h2>
                <Formik
                    initialValues={{
                        appliances: [
                            {
                                easy_fix_appliance_major_group_id: 0,
                                easy_fix_appliance_makes_id: 0,
                                product_description: '',
                                date_purchased: '',
                                serial_number: ''
                            }
                        ] as Appliance[],
                    }}
                    // @ts-ignore
                    innerRef={r => formRef.current = r}
                    onSubmit={async (values) => {
                        const obj = { ...values, application_id: application.application_id, step: 3 };
                        try {
                            const resp = await easyFixService.submit(obj);
                            if (resp.status === 'success') {
                                const resp = await easyFixService.getStep({ application_id: application.application_id, step: 4 });
                                setApplicationStep(resp.data);
                                navigate('/payment-details');
                            } else {
                                navigate('/error')
                            }
                        } catch {
                            navigate('/error')
                        }
                    }}
                >
                    {(formik: any) => {
                        const { handleChange, values, handleBlur, errors, touched, handleSubmit, setFieldValue } = formik;

                        const handleIdTypeChange = (e: any) => {
                            handleChange(e);
                        };

                        const getTitleBorderClass = () => {
                            if (errors.title) {
                                return 'border-red';
                            }
                            return titleFocus || values.title ? 'border-green' : 'border-gray-t';
                        };

                        const getIdTypeBorderClass = () => {
                            if (errors.idType) {
                                return 'border-red';
                            }
                            return idTypeFocus || values.idType ? 'border-green' : 'border-gray-t';
                        }
                        return (
                            <Form className="w-full flex flex-col gap-10">
                                <FieldArray
                                    name="appliances"
                                    render={arrayHelpers => (
                                        <div>
                                            {values.appliances.length === 1 ? values.appliances.map((appliance: any, index: number) => (
                                                <div key={index} className="flex flex-col gap-8 lg:gap-10 mb-24">
                                                    <div className='flex flex-col lg:grid lg:grid-cols-2 gap-8 lg:gap-4'>
                                                        <div className='flex flex-col'>
                                                            <label className='font-semibold text-xs mb-1' htmlFor='applianceCategory'>
                                                                Appliance category
                                                            </label>
                                                            <select
                                                                id='applianceCategory'
                                                                name={`appliances.${index}.easy_fix_appliance_major_group_id`}
                                                                required
                                                                value={appliance.category}
                                                                onChange={handleIdTypeChange}
                                                                onBlur={() => setTitleFocus(false)}
                                                                onFocus={() => setTitleFocus(true)}
                                                                className={`w-full p-1 bg-white-fbg border-b-2 ${getTitleBorderClass()} h-12`}>
                                                                <option value=''>Select appliance category</option>
                                                                {/* @ts-ignore */}
                                                                {applicationStep.step_fields?.product_categories?.map((item) => <option value={item.number}>{item.name}</option>)}
                                                            </select>
                                                        </div>
                                                        <div className='flex flex-col'>
                                                            <label className='font-semibold text-xs mb-1' htmlFor='brandName'>
                                                                Brand Name
                                                            </label>
                                                            <select
                                                                id='brandName'
                                                                name={`appliances.${index}.easy_fix_appliance_makes_id`}
                                                                required
                                                                value={appliance.brand}
                                                                onChange={handleIdTypeChange}
                                                                onBlur={() => setTitleFocus(false)}
                                                                onFocus={() => setTitleFocus(true)}
                                                                className={`w-full p-1 bg-white-fbg border-b-2 ${getTitleBorderClass()} h-12`}>
                                                                <option value=''>Select brand</option>
                                                                {/* @ts-ignore */}
                                                                {applicationStep.step_fields?.brands?.map((item) => <option value={item.number}>{item.name}</option>)}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <TextArea
                                                        label={'Description'}
                                                        required
                                                        className='text-black'
                                                        onChange={handleChange}
                                                        name={`appliances.${index}.product_description`}
                                                        value={appliance.product_description}
                                                        placeholder="Provide a description of the appliance"
                                                    />
                                                    <div className='flex flex-col lg:grid lg:grid-cols-2 gap-8 lg:gap-4 lg:items-center'>
                                                        <div className='flex flex-col gap-1'>
                                                            <p className='font-semibold text-xs mb-1'>Date Purchased</p>
                                                            <DatePicker
                                                                name={`appliances.${index}.date_purchased`}
                                                                value={appliance.date_purchased}
                                                                handleChange={handleChange}
                                                            />
                                                        </div>
                                                        <TextInput
                                                            name={`appliances.${index}.serial_number`}
                                                            label="Serial number"
                                                            placeholder="Type serial number"
                                                            onChange={handleChange}
                                                            value={appliance.serial_number} />
                                                    </div>
                                                </div>
                                            )) : values.appliances.map((appliance: any, index: number) => (
                                                <Accordion expanded={expanded === index} className="mb-8 !rounded-xl !shadow-xl !ov-hidden">
                                                    <AccordionSummary style={{...(expanded === index && { backgroundColor: '#0EC9AE' }), borderBottomLeftRadius: '0px !important', borderBottomRightRadius: 0}} className="!rounded-xl"  onClick={() => setExpanded(expanded === index ? -1 : index)}>
                                                        <div  className={`w-full flex items-center justify-between p-2 lg:p-4 rounded-xl`}>
                                                            <div className="flex flex-col">
                                                                <p className={`text-base lg:text-lg font-bold ${expanded === index && 'text-white'}`}>Appliance {index + 1}</p>
                                                                {appliance.easy_fix_appliance_makes_id && appliance.easy_fix_appliance_makes_id > 0 ? <p className={`mt-4 ${expanded === index && 'text-white'}`}>{applicationStep.step_fields?.brands?.filter((item: any) => item.id === appliance.easy_fix_appliance_makes_id)[0]?.name}</p> : null}
                                                            </div>
                                                            <div className="flex items-center gap-2 lg:gap-4">
                                                                <p className={`${expanded === index && 'text-white text-sm lg:text-base'}`}>{expanded === index ? 'Close' : 'View'}</p>
                                                                <IconButton style={{ backgroundColor: 'lightgrey' }} className="p-1 lg:p-4 flex items-center justify-center">
                                                                    <ChevronRight style={{ transform: `rotate(${expanded === index ? 90 : -90}deg)`, transition: '0.2s all linear' }} />
                                                                </IconButton>
                                                            </div>
                                                        </div>
                                                    </AccordionSummary>
                                                    <AccordionDetails><div key={index} className="w-full flex flex-col gap-8 xl:gap-10 mb-8 pt-8">
                                                        <div className='flex flex-col xl:grid xl:grid-cols-2 gap-8 xl:gap-4'>
                                                            <div className='flex flex-col'>
                                                                <label className='font-semibold text-xs mb-1' htmlFor='applianceCategory'>
                                                                    Appliance category
                                                                </label>
                                                                <select
                                                                    id='applianceCategory'
                                                                    name={`appliances.${index}.easy_fix_appliance_major_group_id`}
                                                                    required
                                                                    value={appliance.category}
                                                                    onChange={handleIdTypeChange}
                                                                    onBlur={() => setTitleFocus(false)}
                                                                    onFocus={() => setTitleFocus(true)}
                                                                    className={`w-full p-1 bg-white-fbg border-b-2 ${getTitleBorderClass()} h-12`}>
                                                                    <option value=''>Select appliance category</option>
                                                                    {/* @ts-ignore */}
                                                                    {applicationStep.step_fields?.product_categories?.map((item) => <option value={item.number}>{item.name}</option>)}
                                                                </select>
                                                            </div>
                                                            <div className='flex flex-col'>
                                                                <label className='font-semibold text-xs mb-1' htmlFor='brandName'>
                                                                    Brand Name
                                                                </label>
                                                                <select
                                                                    id='brandName'
                                                                    name={`appliances.${index}.easy_fix_appliance_makes_id`}
                                                                    required
                                                                    value={appliance.brand}
                                                                    onChange={handleIdTypeChange}
                                                                    onBlur={() => setTitleFocus(false)}
                                                                    onFocus={() => setTitleFocus(true)}
                                                                    className={`w-full p-1 bg-white-fbg border-b-2 ${getTitleBorderClass()} h-12`}>
                                                                    <option value=''>Select brand</option>
                                                                    {/* @ts-ignore */}
                                                                    {applicationStep.step_fields?.brands?.map((item) => <option value={item.number}>{item.name}</option>)}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <TextArea
                                                            label={'Description'}
                                                            required
                                                            className='text-black'
                                                            onChange={handleChange}
                                                            name={`appliances.${index}.product_description`}
                                                            value={appliance.product_description}
                                                            placeholder="Provide a description of the appliance"
                                                        />
                                                        <div className='flex flex-col xl:grid xl:grid-cols-2 gap-8 xl:gap-4 xl:items-center'>
                                                            <div className='flex flex-col gap-1'>
                                                                <p className='font-semibold text-xs mb-1'>Date Purchased</p>
                                                                <DatePicker
                                                                    name={`appliances.${index}.date_purchased`}
                                                                    value={appliance.date_purchased}
                                                                    handleChange={handleChange}
                                                                />
                                                            </div>
                                                            <TextInput
                                                                name={`appliances.${index}.serial_number`}
                                                                label="Serial number"
                                                                placeholder="Type serial number"
                                                                onChange={handleChange}
                                                                value={appliance.serial_number} />
                                                        </div>
                                                    </div></AccordionDetails>
                                                </Accordion>
                                            ))}
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    //@ts-ignore
                                                    setApplicationSubmitAction({ action: formRef.current?.handleSubmit })
                                                    arrayHelpers.push({
                                                        easy_fix_appliance_major_group_id: 0,
                                                        easy_fix_appliance_makes_id: 0,
                                                        product_description: '',
                                                        date_purchased: '',
                                                        serial_number: ''
                                                    })
                                                }
                                                }
                                                className="mt-4 border border-black p-3 rounded-3xl text-sm flex gap-3 items-center cursor-pointer hover:scale-125"
                                            >
                                                Add another appliance
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5 stroke-green hover:stroke-indigo">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                </svg>
                                            </button>
                                        </div>
                                    )}
                                />
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default ApplianceDetails;
