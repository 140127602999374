import React, {useEffect, useRef, useState} from "react";
import { Formik, Form } from 'formik';
import useApplication from "../../hooks/useApplication";
import TextInput from "../../components/Inputs/TextInput";
import { easyFixService } from "../../api/services";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import SpinLoader from "../../components/loaders/Spin";

const PaymentDetails = () => {
    const { application, applicationStep, setApplication, setApplicationStep, applicationValues, setApplicationValues, setApplicationSubmitAction } = useApplication();
    const formRef = useRef<any>();
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);
    const [bankError, setBankError] = useState(false);
    const [loadingText, setLoadingText] = useState('Verifying, please wait...');

    const handleSubmit = async () => {
       await formRef.current?.handleSubmit();
    }
    const getCookie = () => {
        const cookies = document.cookie.split(';');
        const cookieMap: { [key: string]: string } = {}; // Explicitly type cookieMap
        cookies.forEach(cookie => {
            const [name, value] = cookie.trim().split('=');
            cookieMap[name] = value;
        });


        return cookieMap['easyfix_application_id'] || false; // Replace 'cookieName' with the name of your cookie
    };

    const getApplicationStep = async () => {
        const app_id_from_cookie = getCookie();
        let applicationId = '';
        if (!application.application_id && app_id_from_cookie) {
            if (app_id_from_cookie) {
                setApplication({application_id: app_id_from_cookie, step: 1, fields: {}})
                applicationId = app_id_from_cookie;
            }
        } else {
            applicationId = application.application_id;
        }
        const resp = await easyFixService.getStep({application_id: applicationId, step: 4});
        setApplicationStep(resp.data);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if (formRef.current) {
            //@ts-ignore
            setApplicationSubmitAction({ action: () => handleSubmit() })
        }
        if (!applicationStep.step_fields?.field_options['bank_name']) {
            getApplicationStep();
        }
    }, []);

    const getBranchCode = (bank: string) => {
        switch (bank) {
            case 'ABSA BANK':
                return '632005';
            case 'AFRICAN BANK':
                return '430000';
            case 'ALBARAKA BANK LIMITED':
                return '800000';
            case 'BANK OF ATHENS':
                return '410506';
            case 'BIDVEST':
                return '462005';
            case 'CAPITEC BANK':
                return '470010';
            case 'DISCOVERY BANK':
                return '679000';
            case 'FINBOND MUTUAL BANK':
                return '589000';
            case 'FIRST NATIONAL BANK':
                return '250655';
            case 'INVESTEC BANK':
                return '580105';
            case 'MERCANTILE BANK':
                return '450905';
            case 'NEDBANK Inc NBS':
                return '198765-4';
            case 'NEDBANK LTD.':
                return '198765';
            case 'NEDBANK LTD. BOE':
                return '198765-1';
            case 'SASFIN BANK':
                return '683000';
            case 'STANDARD BANK.':
                return '051001';
            case 'TYME DIGITAL':
                return '678910';
            case 'ZERO BANK':
                return '888000';
            default:
                return '';
        }
    }


    return (
        <div className='w-full h-full flex flex-col justify-start py-7 px-4 md:py-7 md:px-10 lg:py-14 lg:px-12 2xl:py-24 2xl:px-28 relative'>

                <div style={submitting ? {display:'block'} : {display:'none'}}>
                    <div className='w-full h-full flex flex-col items-center justify-center py-10 px-14'
                         style={{minHeight: 'calc(100vh - 160px)'}}>
                        <SpinLoader
                            text={loadingText}
                        />
                    </div>
                </div>

                <div className="w-full h-full p-5 lg:p-10" style={submitting ? {display:'none'} : {display:'block'}}><Formik
                    initialValues={{
                        bank_account_number: '',
                        bank_account_type: '',
                        bank_name: '',
                        branch_name: '',
                        branch_number: '',
                        debit_order_day: ''
                }}
                validationSchema={Yup.object().shape({
                    bank_account_number: Yup.string().matches(/^[0-9]{6,}$/, 'Please enter in a valid account number').required('Account Number is required'),
                    bank_account_type: Yup.string().required('Account Type is required'),
                    bank_name: Yup.string().required('Bank Name is required'),
                    branch_name: Yup.string().required('Branch Name is required'),
                    branch_number: Yup.string().required('Branch Number is required'),
                    debit_order_day: Yup.number()
                    .min(1, 'Please select a valid Debit Order Day') // Additional validation if needed
                    .required('Debit Order Day is required')
                })}
                // @ts-ignore
                innerRef={(r) => formRef.current = r}

                onSubmit={async (values) => {
                    setSubmitting(true);
                    const resp = await easyFixService.submit({ application_id: application.application_id, step: 4, ...values });
                    if (resp.status === 'success') {
                        setLoadingText('Please wait while we generate your contract. This may take a minute, please don\'t close your browser.');
                        const resp = await easyFixService.getStep({ application_id: application.application_id, step: 5 });
                        setApplicationStep(resp.data);
                        navigate('/debit-order-mandate');
                    } else {
                        setSubmitting(false);
                        setBankError(true);
                    }
                }}
            >
                {(formik: any) => {
                    const { handleChange, values, handleBlur, errors, touched, handleSubmit, setFieldValue } = formik;
                    return (
                        <Form className="w-full flex flex-col gap-10">
                            <p className=' text-2xl lg:text-3xl font-semibold text-gray-h'>Payment Details</p>
                            {bankError ? (
                                <p className=' text-xl text-red'>Error: please check bank details and try again</p>
                            ) : null}
                            <div className="w-full flex flex-col xl:grid xl:grid-cols-2 gap-10">
                                <div className='flex flex-col'>
                                    <label className='font-semibold text-xs mb-1' htmlFor='bank_name'>
                                        Bank Name
                                    </label>
                                    <select
                                        id='bank_name'
                                        name='bank_name'
                                        required
                                        value={values.bank_name}
                                        onChange={(e) => {
                                            handleChange(e);
                                            const branchCode = getBranchCode(e.target.value);
                                            if (branchCode !== '') {
                                                setFieldValue('branch_number', branchCode);
                                                setFieldValue('branch_name', 'Universal');
                                            } else {
                                                setFieldValue('branch_number', '');
                                                setFieldValue('branch_name', '');
                                            }
                                        }}
                                        className={`w-full p-1 bg-white-fbg border-b-2 h-12`}>
                                        <option value=''>Select Bank</option>
                                        {
                                            // @ts-ignore
                                            applicationStep.step_fields?.field_options['bank_name']?.map((item) =>
                                                <option value={item.name}>{item.name}</option>)
                                        }
                                        {/* <option value='option1'>Mr</option>
                                        <option value='option2'>Mrs.</option>
                                        <option value='option3'>Ms</option> */}
                                    </select>
                                    {errors.bank_name && touched.bank_name &&
                                        <div className='text-red'>{errors.bank_name}</div>}
                                </div>
                                <div className='flex flex-col'>
                                    <label className='font-semibold text-xs mb-1' htmlFor='bank_account_type'>
                                        Account Type
                                    </label>
                                    <select
                                        id='bank_account_type'
                                        name='bank_account_type'
                                        required
                                        value={values.bank_account_type}
                                        onChange={handleChange}
                                        className={`w-full p-1 bg-white-fbg border-b-2 h-12`}>
                                        <option value=''>Select Account Type</option>
                                        {
                                            // @ts-ignore
                                            applicationStep.step_fields?.field_options['account_type']?.map((item) =>
                                                <option value={item.name}>{item.name}</option>)
                                        }
                                    </select>
                                    {errors.bank_account_type && touched.bank_account_type &&
                                        <div className='text-red'>{errors.bank_account_type}</div>}
                                </div>
                            </div>
                            <div className="w-full flex flex-col xl:grid xl:grid-cols-2 gap-10">
                                <TextInput
                                    label={'Branch name'}
                                    required
                                    onChange={handleChange}
                                    name='branch_name'
                                    value={values.branch_name}
                                    error={errors.branch_name && touched.branch_name ? errors.branch_name : null}
                                    placeholder='Type branch name'
                                />
                 
                                <TextInput
                                    label={'Branch Code'}
                                    required
                                    onChange={handleChange}
                                    name='branch_number'
                                    value={values.branch_number}
                                    error={errors.branch_number && touched.branch_number ? errors.branch_number : null}
                                    placeholder='Type Branch Code'
                                />
                            </div>
                            <div className="w-full flex flex-col xl:grid xl:grid-cols-2 gap-10">
                                <TextInput
                                    label={'Account Number'}
                                    required
                                    onChange={handleChange}
                                    name='bank_account_number'
                                    value={values.bank_account_number}
                                    error={errors.bank_account_number && touched.bank_account_number ? errors.bank_account_number : null}
                                    placeholder='Type Account Number'
                                />

                                <div className='flex flex-col'>
                                    <label className='font-semibold text-xs mb-1' htmlFor='bank_account_type'>
                                        Debit Day
                                    </label>
                                    <select
                                        id='debit_order_day'
                                        name='debit_order_day'
                                        required
                                        value={values.debit_order_day}
                                        onChange={handleChange}
                                        className={`w-full p-1 bg-white-fbg border-b-2 h-12`}>
                                        <option value=''>Select Debit Day</option>
                                        {
                                            // @ts-ignore
                                            applicationStep.step_fields?.field_options['debit_day']?.map((item) =>
                                                <option value={item.name}>{item.name}</option>)
                                        }
                                        {/* <option value='option1'>Mr</option>
                                        <option value='option2'>Mrs.</option>
                                        <option value='option3'>Ms</option> */}
                                    </select>
                                    {errors.debit_order_day && touched.debit_order_day &&
                                        <div className='text-red'>{errors.debit_order_day}</div>}
                                </div>
                            </div>
                        </Form>
                    );
                }}
                </Formik></div>
        </div>
    );
};

export default PaymentDetails;
