import * as api from '..';
import { EASYFIX } from '../config/endpoints';

interface EasyFixService {
    startApplication: () => Promise<any>;
    getApplication: (body: { application_id: string | undefined }) => Promise<any>;
    submit: (body: any) => Promise<any>;
    getStep: (body: { application_id: string; step: number }) => Promise<any>;
}

const easyFixService: EasyFixService = {
    startApplication: async () => {
        const resp = await api.post(EASYFIX.START_APPLICATION, {});
        return resp;
    },
    getApplication: async (body) => {
        const resp = await api.post(EASYFIX.GET_APPLICATION, body);
        return resp;
    },
    submit: async (body) => {
        const resp = await api.post(EASYFIX.SUBMIT, body);
        return resp;
    },
    getStep: async (body) => {
        const resp = await api.post(EASYFIX.GET_STEP, body);
        return resp;
    }
};

export default easyFixService;
