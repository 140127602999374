import React, { ReactElement } from 'react';
import { CardActionArea, CircularProgress } from '@material-ui/core';
import useResponsiveness from '../../../hooks/useResponsiveness';

interface Props {
    label: any;
    fullWidth: boolean;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    type?: string;
    href?: any;
    endIcon?: any;
    disabled?: boolean;
    style?: any;
    loading?: boolean;
    buttonColor?: string;
    id?: string;
    marginAuto?: boolean;
    startIcon?: ReactElement;
}

function SimpleButton({
    label,
    type,
    href = null,
    fullWidth,
    endIcon,
    onClick,
    disabled,
    style,
    loading,
    buttonColor = '',
    id,
    marginAuto = true,
    startIcon,
}: Props): ReactElement {
    const widthValue = fullWidth ? '100%' : 'fit-content';
    const { isMobile } = useResponsiveness();

    return (
        <div
            className={`${disabled ? 'disabled1' : type ? type : 'primary'} bg-green text-white rounded-3xl ov-hidden`}
            style={{
                width: widthValue,
                minWidth: !isMobile ? 200 : 160,
                minHeight: 40,
                textAlign: 'center',
                ...(marginAuto && { margin: 'auto' }),
                ...(style && { ...style }),
            }}>
            <CardActionArea onClick={disabled ? () => null : onClick} {...(id && { id })}>
                <div
                    className={`relative flex text-white pt-2 ${endIcon ? 'justify-between px-9 lg:px-10 py-2' : 'justify-center'} items-center ${buttonColor}`}
                    style={{ maxHeight: 46, fontSize: 14, minHeight: 35, fontWeight: 700 }}>
                    {loading ? (
                        <div className={'h-full w-w-full flex items-center justify-center'}>
                            <CircularProgress color='secondary' style={{ maxWidth: 20, maxHeight: 20 }} />
                        </div>
                    ) : (
                        <>
                            {startIcon ? startIcon : null} {label} {endIcon ? endIcon : null}
                        </>
                    )}
                </div>
            </CardActionArea>
        </div>
    );
}

export default SimpleButton;
