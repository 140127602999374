import { IconButton } from '@material-ui/core'
import { FileCopySharp } from '@material-ui/icons'
import React, { useEffect } from 'react'
import useApplication from '../../hooks/useApplication'

type Props = {}


const Error = (props: Props) => {
    const { setApplicationStatus } = useApplication();

    useEffect(() => {
        setApplicationStatus('error');
    }, []);

    return (
        <div className='w-full h-full flex flex-col justify-start py-2 px-4 md:py-4 md:px-10 lg:py-0 lg:px-12 2xl:py-12 2xl:px-28'>
            <div className="w-full h-full p-10 flex flex-col items-center justify-center gap-8">
                <p className='text-3xl font-bold'>Error</p>
                <p className='px-16 text-center'>We encountered an error processing your application, please try again later.</p>
                <div className='w-full mt-6'>
                    <div className='shadow-lg rounded-xl w-full px-6 py-12 flex flex-row items-center justify-between'> 
                            <p>Account Queries</p>
                            <div className='flex flex-row items-center gap-4'>
                                <p className='font-bold'>techadmin@teljoy.co.za</p>
                                <IconButton onClick={() => navigator.clipboard.writeText('techadmin@teljoy.co.za')
                                }><FileCopySharp /></IconButton>
                            </div>
                    </div>
                    <div className='shadow-lg rounded-xl w-full px-6 py-12 flex flex-row items-center justify-between'> 
                            <p>To log a service call</p>
                            <div className='flex flex-row items-center gap-4'>
                                <p className='font-bold'>0860 224 466</p>
                                <IconButton onClick={() => navigator.clipboard.writeText('+27860224466')
                                }><FileCopySharp /></IconButton>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Error