import React, { createContext, useContext, useState, ReactNode } from 'react';

interface FormStateProviderProps {
  children: ReactNode;
}

const FormStateContext = createContext<{
  isFormFilled: boolean;
  setIsFormFilled: (filled: boolean) => void;
} | null>(null);

export const useFormState = () => useContext(FormStateContext);

export const FormStateProvider: React.FC<FormStateProviderProps> = ({ children }) => {
  const [isFormFilled, setIsFormFilled] = useState(false);

  return (
    <FormStateContext.Provider value={{ isFormFilled, setIsFormFilled }}>
      {children}
    </FormStateContext.Provider>
  );
};
