import React, { ChangeEvent, useState } from 'react';

interface Props {
    label: string;
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    value: string;
    required?: boolean;
    name: string;
    error?: string;
    style?: React.CSSProperties;
    className?: string;
    placeholder?: string;
    password?: boolean;
    onBlur?: (e: any) => void;
}

const TextArea = ({
    label,
    onChange,
    value,
    required,
    name,
    error,
    style = {},
    className = '',
    placeholder,
    password = false,
    onBlur,
}: Props) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => setIsFocused(true);
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(false);
    };

    const borderColor = error ? 'border-red' : isFocused || value ? 'border-green' : 'border-gray-t';

    return (
        <div className='flex flex-col gap-1'>
            <div className={`relative w-full h-52 flex flex-col justify-between border-b-2 ${borderColor} ${className}`} style={style}>
                <p className='font-semibold  text-xs text-black mb-1'>
                    {label}
                </p>
                <textarea
                    className='w-full h-full pl-5 pr-5 border-none bg-white-fbg'
                    name={name}
                    value={value}
                    onChange={onChange}
                    maxLength={255}
                    {...(placeholder && { placeholder })}
                    {...(onBlur && { onBlur })}
                />
            </div>
            {required && <p className='text-xs font-normal text-gray-b-t'>Optional</p>}
        </div>
    );
};

export default TextArea;