import React, {useEffect} from 'react';
import SimpleButton from '../Buttons/Button';
import { useNavigate } from 'react-router-dom';
import { useFormState } from '../Context/FormState';
import useApplication from '../../hooks/useApplication';

const Footer = () => {
    const navigate = useNavigate();
    const { applicationSubmitAction, setApplicationSubmitAction } = useApplication();
    const { pathname } = window.location;

    const nextAction = () => {
        applicationSubmitAction.action();
        // setApplicationSubmitAction({ action: () => {} });
    };

    const backToTop = () => {
        const el = document.getElementsByTagName('main')[0].parentElement;
        if (el) {
            // el.scrollTo({ behavior: 'smooth', top: 0 });
            window.scrollTo({top:0,behavior:"smooth"});
        }
    }

    return (
        <div className='bg-white-fbg w-max h-fit shadow-xl flex px-5 py-5' style={{ width: '100%' }}>
            <div onClick={backToTop} className='flex cursor-pointer justify-between items-baseline lg:p-5 w-full lg:pl-[22rem]'>
                <div className='flex gap-3 items-center md:ml-5 lg:ml-10 '>
                    <p className='text-xs text-gray-t'>Back to Top</p>
                    <div className='flex justify-center rounded-full border border-gray-b-t p-1 lg:p-3'>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 24 24'
                            fill='#ADB5BD'
                            className='w-4 h-4 lg:w-6 lg:h-6 hover:fill-green cursor-pointer'>
                            <path
                                fill-rule='evenodd'
                                d='M11.47 2.47a.75.75 0 0 1 1.06 0l3.75 3.75a.75.75 0 0 1-1.06 1.06l-2.47-2.47V21a.75.75 0 0 1-1.5 0V4.81L8.78 7.28a.75.75 0 0 1-1.06-1.06l3.75-3.75Z'
                                clip-rule='evenodd'
                            />
                        </svg>
                    </div>
                </div>
                {pathname !== '/complete' && (
                    <div className='self-end lg:pr-10'>
                        <SimpleButton
                            label={'Continue'}
                            fullWidth={false}
                            onClick={nextAction}
                            endIcon={
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 20 20'
                                    fill='white'
                                    className='w-5 h-5'>
                                    <path
                                        fill-rule='evenodd'
                                        d='M3 10a.75.75 0 0 1 .75-.75h10.638L10.23 5.29a.75.75 0 1 1 1.04-1.08l5.5 5.25a.75.75 0 0 1 0 1.08l-5.5 5.25a.75.75 0 1 1-1.04-1.08l4.158-3.96H3.75A.75.75 0 0 1 3 10Z'
                                        clip-rule='evenodd'
                                    />
                                </svg>
                            }
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Footer;
