import { useState, useEffect } from 'react';

const useResponsiveness = (value = 768) => {
    const [isMobile, setIsMobile] = useState(false);
    const [width, setWidth] = useState(typeof window !== 'undefined' ? window?.innerWidth : 0);

    const getSize = () => {
        setWidth(typeof window !== 'undefined' ? window?.innerWidth : 0);
        if (window?.innerWidth > value) {
            setIsMobile(false);
        } else {
            setIsMobile(true);
        }
    };

    useEffect(() => {
        getSize();
        window?.addEventListener('resize', getSize);
        return () => {
            window?.removeEventListener('resize', getSize);
        };
    }, []);

    return {
        isMobile,
        width,
    };
};

export default useResponsiveness;
