import React, { useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import WelcomeCard from "../../components/Cards/WelcomeCards";
import AccordionTransition from "../../components/accordions/WelcomeAccordion";
import { easyFixService } from "../../api/services";
import useApplication from "../../hooks/useApplication";
import SpinLoader from "../../components/loaders/Spin";

const Application = () => {
    let navigate = useNavigate();
    const { applicationId } = useParams();
    const { application, setApplication } = useApplication();

    useEffect(() => {
       getApplication();
    }, []);
    
    const getApplication = async () => {
        try {
            const resp = await easyFixService.getApplication({ application_id: applicationId });
            if (resp.status === 'success') {
                setApplication(resp.data);
                const expirationDate = new Date();
                expirationDate.setTime(expirationDate.getTime() + (20 * 60 * 1000)); // 20 minutes
                const expires = "expires=" + expirationDate.toUTCString();
                document.cookie = `easyfix_application_id=${applicationId};` + expires + ";path=/";
                navigate(`/welcome`);
            } else {
                // navigate('/error');
                navigate('/generate');
            }
        } catch {
            navigate('/error');
        }
        
    }

    return (
        <div className='w-full h-full flex flex-col items-center justify-center py-10 px-14' style={{ minHeight: 'calc(100vh - 160px)' }}>
            <SpinLoader
                text={'Loading'}
            />
        </div>
    );
};

export default Application;
