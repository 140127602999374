import styles from "./styles.module.css";

export default function WelcomeVideo(){
    return <div className="pb-16 -mt-24 md:mt-0 w-full flex items-center justify-center">
    <iframe
        height='auto'
        className={styles.video}
        src='https://www.youtube.com/embed/Q4iXJnKiYIA?autoplay=1'
        title='YouTube video player'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
    ></iframe>
</div>
}