import React, {useEffect, useRef, useState} from "react";
import Debit from "./debit";
import Banking from "./banking";
import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import useApplication from "../../hooks/useApplication";
import {easyFixService} from "../../api/services";
import * as Yup from "yup";
import SpinLoader from "../../components/loaders/Spin";

const DebitOrderMandate = () => {
    const navigate = useNavigate();
    const formRef = useRef<any>();
    const { application, applicationStep, setApplicationStep, applicationValues, setApplicationValues, setApplicationSubmitAction } = useApplication();
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = () => {
        setSubmitting(true);
        formRef.current?.handleSubmit()
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        if (formRef.current) {
            //@ts-ignore
            setApplicationSubmitAction({ action: () => handleSubmit() })
        }
    }, []);

    return (
        <div className='w-full h-full flex flex-col justify-start py-10 px-5 lg:px-14'>
            {submitting ? (
                <div className='w-full h-full flex flex-col items-center justify-center py-10 px-14'
                     style={{minHeight: 'calc(100vh - 160px)'}}>
                    <SpinLoader
                        text={'Your application is being submitted, please wait for confirmation. This may take a minute.'}
                    />
                </div>
            ) : (
            <div className='flex flex-col gap-8 lg:p-10 w-full'>
                <p className='text-xl md:text-2xl lg:text-3xl font-semibold text-gray-h'>Confirmation of Debit Order Authority</p>
                <embed src={applicationStep.step_fields?.contract_link} type="application/pdf" className="w-full" style={{minHeight: '100vh'}} />
                <Formik
                    initialValues={{
                        acceptPrivacy: ''
                    }}
                    innerRef={(r) => formRef.current = r}
                    validationSchema={Yup.object().shape({
                        acceptPrivacy: Yup.boolean().required(),
                    })}
                    onSubmit={async (values) => {
                        const resp = await easyFixService.submit({ application_id: application.application_id, step: 5, ...values });
                        if (resp.status === 'success') {
                            navigate('/complete');
                        }
                    }}>
                    {(formik: any) => {
                        const { handleChange, values, handleBlur, errors, touched, handleSubmit, setFieldValue } = formik;
                        const handleRadioButton = (value: string) => {
                            setFieldValue('acceptPrivacy', value);
                        };

                        return (
                            <Form className='w-full'>
                                <div className='flex flex-col gap-5 w-full'>
                                    <p className='text-lg xl:text-xl font-semibold text-gray-h'>Debit order confirmation</p>
                                    <p className='text-sm text-black'>By clicking ‘I accept’ I acknowledge that the details supplied are correct.</p>
                                    <div className='flex items-center mt-4 bg-white-t rounded-3xl p-4 w-fit'>
                                        <Field type="checkbox" id={'acceptPrivacy'} name="acceptPrivacy" value={values.acceptPrivacy} checked={values.acceptPrivacy} onChange={(e: any) => handleChange({ target: { name: e.target.name, value: e.target.checked ? true : false } })} />
                                        <label htmlFor="acceptPrivacy" className='ml-2 text-black font-semibold text-xs'>
                                            I accept the Terms and Conditions
                                        </label>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
            )}
        </div >
    );
};

export default DebitOrderMandate;
