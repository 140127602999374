const EASYFIX = {
    START_APPLICATION: 'easyfix/application/start',
    GET_APPLICATION: 'easyfix/application',
    SUBMIT: 'easyfix/application/submit',
    GET_STEP: 'easyfix/application/step'
};

export {
    EASYFIX
};
