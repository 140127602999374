import { atom, useAtom } from 'jotai';
import { useEffect } from 'react';

const applicationAtom = atom({
    application_id: '',
    step: 1,
    fields: {}
});
const applicationStepAtom = atom<any>({});
const applicationSubmitActionAtom = atom({ action: () => {} });
const applicationValuesAtom = atom({});
const applicationStatusAtom = atom<'in-progress' | 'complete' | 'error' | 'declined'>('in-progress');
const applicationLoadingAtom = atom<boolean>(false);

const useApplication = () => {
    const [application, setApplication] = useAtom(applicationAtom);
    const [applicationStep, setApplicationStep] = useAtom(applicationStepAtom);
    const [applicationSubmitAction, setApplicationSubmitAction] = useAtom(applicationSubmitActionAtom);
    const [applicationValues, setApplicationValuesDispatch] = useAtom(applicationValuesAtom)
    const [applicationStatus, setApplicationStatus] = useAtom(applicationStatusAtom);
    const [applicationLoading,setApplicationLoading] = useAtom(applicationLoadingAtom);
    useEffect(()=>{
        if(applicationLoading){
            window.scrollTo({top:0})
        }
    },[applicationLoading]);
    const setApplicationValues = (val: any | null) => {
        if (!val) {
            setApplicationValuesDispatch({});
        } else {
            setApplicationValuesDispatch({ ...applicationValues, ...val })
        }
    }

    return {
        application, setApplication,
        applicationStep, setApplicationStep,
        applicationSubmitAction, setApplicationSubmitAction,
        applicationValues, setApplicationValues,
        applicationStatus, setApplicationStatus,
        applicationLoading, setApplicationLoading
    }
}

export default useApplication;
