import axios from 'axios';

let token: string | null = null;

export const setToken = (t: string) => {
    token = t;
};

const axiosDefault = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosDefault.interceptors.request.use(
    (request) => {
        if (token) {
            request.headers['Authorization'] = `Bearer ${token}`;
        }
        if (typeof window !== 'undefined') {
            request.headers['CurrentPage'] = window.location.href;
        }
        return request;
    },
    (error) => {
        return Promise.reject(error);
    },
);

axiosDefault.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return error.response;
    },
);

const get = async (url: string): Promise<any> => {
    const resp = await axiosDefault.get(url);
    if (resp && resp.data) {
        return resp.data;
    }
    return false;
};

const post = async (url: string, body: any): Promise<any> => {
    const resp = await axiosDefault.post(url, body);
    if (resp && resp.data) {
        return resp.data;
    }
    return false;
};

export { get, post };
