import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import WelcomeCard from "../../components/Cards/WelcomeCards";
import AccordionTransition from "../../components/accordions/WelcomeAccordion";
import useApplication from "../../hooks/useApplication";
import { easyFixService } from "../../api/services";
import WelcomeVideo from "../../components/Videos/WellcomeVideo";

const Welcome = () => {
    let navigate = useNavigate();
    const { application, setApplicationSubmitAction, setApplicationStep ,setApplicationLoading } = useApplication();
    //states
    const [ didNotReadTermsAndConditions ,setDidNotReadTermsAndConditions ] = React.useState(false);
	const [accepted, setAccepted] = React.useState(false);

    const goToNextStep = async () => {
        if(!accepted){
            setTimeout(()=>{
                window.scrollTo({top:window.innerHeight * 2,behavior:"smooth"}) 
            },1000)
            return setDidNotReadTermsAndConditions(true);
        }
        setApplicationLoading(true);
        const resp = await easyFixService.getStep({ application_id: application.application_id, step: 1 });
        setApplicationLoading(false);
        setApplicationStep(resp.data);
        navigate('/personal-details');
    };

    const openTsAndCs = () =>{
        window.scrollTo({top:0});
        navigate("/terms-and-conditions");
    }
    
    useEffect(() => {
        setApplicationSubmitAction({ action: goToNextStep });
        setApplicationLoading(false);
    }, [accepted]);

    
    return (
        <div className='w-full h-full flex flex-col justify-start py-2 px-5 md:py-4 md:px-10 lg:py-10 lg:px-14 xl:py-12 xl:px-28'>
            <WelcomeVideo/>
            <div className="flex flex-col gap-10 w-full border-b border-black-b pb-8">
                <h1 className="font-semibold text-2xl xl:text-[2.5rem]">EasyFix Maintenance Plan</h1>
            </div>
            <WelcomeCard />
            <AccordionTransition />
            <div className='mb-10 lg:mb-0'>
                <div>
                    <p className="font-bold text-lg pt-12 border-t border-black-t">Terms and Conditions</p>
                    <p className="my-6">By clicking 'I accept' you acknowledge the <span onClick={openTsAndCs} style={{cursor:"pointer",textDecoration:'underline'}}>Terms
                        and Conditions</span> outlined and accept that:</p>
                    <ul>
                        <li><p className='text-sm md:text-base'>There is a 3-month waiting period from the date of initiation until the first service call can be logged.</p></li>
                        <li className="my-2"><p className='text-sm md:text-base'>- Costs covered in your monthly installment include call-out fees and spares up to R50 000 per annum (R5 000 per call).</p></li>
                        <li><p>- Units must not be older than 8 years.</p></li>
                        <li className="my-2"><p className='text-sm md:text-base'>- You agree to the contract exclusions which may apply.</p></li>
                    </ul>
                    <label htmlFor="agree" className="mt-4 text-sm md:text-base flex items-center">
                        <input
                            type="checkbox"
                            id="agree"
                            name="agree"
                            className="mr-2"
                            checked={accepted}
                            onClick={() => [setAccepted(!accepted)]}
                        />
                        I accept
                    </label>
                    {didNotReadTermsAndConditions && !accepted && <>
                        <p className="font-bold pt-4 mt-0  text-red-300">Please accept our Terms and Conditions.</p>
                    </>}
                </div>

            </div>
        </div>
    );
};

export default Welcome;
