import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const info = [
	{
		img:"/img/icons/svgs/home-repair.svg",
		text:"A complete household and repair solution.",
	},
	{
		img:"/img/icons/svgs/repair-centre.svg",
		text:"No hassle finding a repair center.",
	},
	{
		img:"/img/icons/svgs/no-call-out-fee.svg",
		text:"No call out fees (up to R50 000 per annum).",
	},
	{
		img:"/img/icons/svgs/save-money.svg",
		text:"Save on spares and labour (up to R50 000 per annum).",
	}
];

export default function AccordionTransition() {
	const [expanded1, setExpanded1] = React.useState(false)
	/* const [expanded2, setExpanded2] = React.useState(false); */

    info.map(inf=>{
        const link = document.createElement("link");
        link.setAttribute("rel","prefetch");
        link.setAttribute("href",inf.img);
        document.head.appendChild(link);
    })

	const handleExpansion1 = () => {
		setExpanded1((prevExpanded) => !prevExpanded);
	};

	/* const handleExpansion2 = () => {
		setExpanded2((prevExpanded) => !prevExpanded);
	}; */


	return (
		<div className="flex flex-col gap-10 py-10 w-full border-b border-black-b">
			<div className="flex w-100 flex-wrap md:flex-nowrap">
				<div className="w-full">
				<p className="font-semibold text-gray-h text-xl xl:text-2xl pb-4">
				How does Easyfix work?
			</p>
        
			<p className='text-sm xl:text-base'>

            <ul>
				{info.map(({img,text}:any,index:number)=>{
					return <li key={index} className="flex w-full items-center pb-4">
                        <img src={img} width={50} height={50} alt={"ico"} className="mr-4 w-[50px] h-[50px] object-contain object-center"/> <p>{text}</p>
					</li>
				})}
			</ul>
			</p>
				</div>
				
			</div>
			<div>
				<Accordion
					expanded={expanded1}
					onChange={handleExpansion1}
					style={{ margin: '0' }}
				>
					<AccordionSummary
						expandIcon={expanded1 ? <RemoveIcon /> : <AddIcon />}
						aria-controls="panel1-content"
						id="panel1-header"
					>
						<Typography><h1 className="text-base font-semibold md:text-lg xl:text-2xl">Easyfix plan</h1></Typography>
						<Typography className="p-2" style={{ marginLeft: 'auto' }}><p className='text-xs md:text-sm xl:text-base'>View Benefits</p></Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							<ul className='flex flex-col gap-3'>
								<li><p className='text-sm xl:text-base'>- Cover 5 appliances or electronics for only R99 per month.</p></li>
								<li><p className='text-sm xl:text-base'>- EasyFix agents will repair the appliance in your home if possible. If it needs to be repaired offsite, collection and delivery is included.</p></li>
								<li><p className='text-sm xl:text-base'>- Flexible packages, customisable to suit the products relevant to you.</p></li>
								<li><p className='text-sm xl:text-base'>- EasyFix offers a 3-month guarantee on all repairs.</p></li>
								<li><p className='text-sm xl:text-base'>- Costs covered in your monthly installment include call-out fees and spares (up to R50 000 per annum).</p></li>
							</ul>
						</Typography>
					</AccordionDetails>
				</Accordion>
				<div className="bg-red w-100 rounded-b-lg color text-white flex justify-between p-5">
					<p className="text-xl xl:text-2xl text-right p-2 text-white">R99</p>
					<p className="text-white text-right text-sm xl:text-base">Per month <br></br>Includes VAT</p>
				</div>
			</div>
		</div>
	);
};
