import { CircularProgress } from '@material-ui/core'
import React from 'react'

interface Props {
    text: string
}

const SpinLoader = ({text}: Props) => {
  return (
    <div className='flex flex-col items-center justify-center'>
        <CircularProgress />
        <p className='text-lg mt-4'>{text}</p>
    </div>
  )
}

export default SpinLoader
