import { useState, useEffect } from 'react';

function useSwipeOrScrollDirection() {
  const [direction, setDirection] = useState({ up: false, down: false });

  useEffect(() => {
    let startY = 0;
    let lastScrollY = window.pageYOffset;

    const handleTouchStart = (e:any) => {
      startY = e.touches[0].clientY;
    };

    const handleTouchMove = (e:any) => {
      const currentY = e.touches[0].clientY;
      if (startY - currentY > 10) {
        setDirection({ up: true, down: false });
      } else if (currentY - startY > 10) {
        setDirection({ up: false, down: true });
      }
    };

    const handleScroll = () => {
      const currentScrollY = window.pageYOffset;
      if (lastScrollY > currentScrollY) {
        setDirection({ up: true, down: false });
      } else if (lastScrollY < currentScrollY) {
        setDirection({ up: false, down: true });
      }
      lastScrollY = currentScrollY;
    };

    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchmove', handleTouchMove);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return [direction.up, direction.down];
}

export default useSwipeOrScrollDirection;
