import React from "react";

const WelcomeCard = () => {
    return (
        <div className="flex flex-col gap-10 py-10 w-full border-b border-black-b">
            <p className="font-semibold text-gray-h text-xl xl:text-2xl">
                Follow the easy sign-up process and get cover for your precious appliances!
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
                <div className="max-w-md rounded overflow-hidden shadow-lg bg-white p-6 flex flex-col items-center">
                    <div className="rounded-full bg-gray-200 w-16 h-16 flex justify-center items-center mb-4">
                        <svg id="icon_branding_24px_user_static" data-name="icon/branding/24px/user/static" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <g id="Group_1169" data-name="Group 1169">
                                <path id="Path_794" data-name="Path 794" d="M20.485,15.515a11.954,11.954,0,0,0-4.56-2.86,6.938,6.938,0,1,0-7.85,0A12.019,12.019,0,0,0,0,24H1.875a10.125,10.125,0,1,1,20.25,0H24A11.922,11.922,0,0,0,20.485,15.515ZM12,12a5.063,5.063,0,1,1,5.062-5.062A5.068,5.068,0,0,1,12,12Z" transform="translate(0)" fill="#ff003e" />
                            </g>
                        </svg>
                    </div>
                    <h2 className="text-lg xl:text-xl font-bold mb-2">Personal Details</h2>
                </div>
                <div className="max-w-md rounded overflow-hidden shadow-lg bg-white p-6 flex flex-col items-center">
                    <div className="rounded-full bg-gray-200 w-16 h-16 flex justify-center items-center mb-4">
                        <svg id="icon_branding_24px_file_static" data-name="icon/branding/24px/file/static" xmlns="http://www.w3.org/2000/svg" width="19.878" height="24" viewBox="0 0 19.878 24">
                            <g id="Group_1168" data-name="Group 1168">
                                <path id="Path_793" data-name="Path 793" d="M57.986,0H50.532A2.816,2.816,0,0,0,47.72,2.813v.935h-.938A2.816,2.816,0,0,0,43.969,6.56V21.187A2.816,2.816,0,0,0,46.782,24h10.97a2.816,2.816,0,0,0,2.813-2.813V20.25h.469a2.816,2.816,0,0,0,2.813-2.813V5.894ZM58.5,3.179l2.15,2.162H59.44A.939.939,0,0,1,58.5,4.4Zm.188,18.008a.939.939,0,0,1-.938.938H46.782a.939.939,0,0,1-.938-.938V6.56a.939.939,0,0,1,.938-.938h.938V17.44a2.816,2.816,0,0,0,2.813,2.813l8.157,0Zm3.282-3.75a.939.939,0,0,1-.938.937l-10.5,0a.939.939,0,0,1-.938-.937V2.813a.939.939,0,0,1,.938-.937h6.095V4.4A2.816,2.816,0,0,0,59.44,7.216h2.532V17.437Z" transform="translate(-43.969)" fill="#ff003e" />
                            </g>
                        </svg>
                    </div>
                    <h2 className="text-lg xl:text-xl font-bold mb-2">SA ID/Passport No.</h2>
                </div>
                <div className="max-w-md rounded overflow-hidden shadow-lg bg-white p-6 flex flex-col items-center">
                    <div className="rounded-full bg-gray-200 w-16 h-16 flex justify-center items-center mb-4">
                        <svg id="icon_branding_24px_credit-card_static" data-name="icon/branding/24px/credit-card/static" xmlns="http://www.w3.org/2000/svg" width="27.429" height="24" viewBox="0 0 27.429 24">
                            <g id="Group_1171" data-name="Group 1171" transform="translate(0 5.143)">
                                <g id="Group_1170" data-name="Group 1170" transform="translate(0 0)">
                                    <path id="Path_795" data-name="Path 795" d="M26.572,138.282a.857.857,0,0,0-.857.857v6h-24V134.853h9.429a.857.857,0,0,0,0-1.714H1.714V129.71h9.429a.857.857,0,0,0,0-1.714H1.714A1.715,1.715,0,0,0,0,129.71v15.429a1.715,1.715,0,0,0,1.714,1.714h24a1.715,1.715,0,0,0,1.714-1.714v-6A.857.857,0,0,0,26.572,138.282Z" transform="translate(0 -127.996)" fill="#ff003e" />
                                </g>
                            </g>
                            <g id="Group_1173" data-name="Group 1173" transform="translate(3.429 15.428)">
                                <g id="Group_1172" data-name="Group 1172">
                                    <path id="Path_796" data-name="Path 796" d="M68.286,320H64.857a.857.857,0,1,0,0,1.714h3.429a.857.857,0,1,0,0-1.714Z" transform="translate(-64 -319.996)" fill="#ff003e" />
                                </g>
                            </g>
                            <g id="Group_1175" data-name="Group 1175" transform="translate(13.715 0)">
                                <g id="Group_1174" data-name="Group 1174" transform="translate(0 0)">
                                    <path id="Path_797" data-name="Path 797" d="M269.2,34.644l-6-2.571a.875.875,0,0,0-.677,0l-6,2.571a.859.859,0,0,0-.518.789v3.429c0,4.716,1.743,7.473,6.43,10.173a.862.862,0,0,0,.854,0c4.687-2.693,6.43-5.45,6.43-10.173V35.432A.858.858,0,0,0,269.2,34.644ZM268,38.861c0,3.958-1.31,6.137-5.143,8.434-3.833-2.3-5.143-4.481-5.143-8.434V36l5.143-2.2L268,36Z" transform="translate(-256 -32.004)" fill="#ff003e" />
                                </g>
                            </g>
                            <g id="Group_1177" data-name="Group 1177" transform="translate(17.144 5.145)">
                                <g id="Group_1176" data-name="Group 1176" transform="translate(0 0)">
                                    <path id="Path_798" data-name="Path 798" d="M326.547,128.2a.863.863,0,0,0-1.205.134l-2.695,3.37-1.066-1.594a.857.857,0,1,0-1.426.95l1.714,2.571a.864.864,0,0,0,.677.382h.036a.857.857,0,0,0,.67-.322l3.429-4.286A.858.858,0,0,0,326.547,128.2Z" transform="translate(-320.011 -128.016)" fill="#ff003e" />
                                </g>
                            </g>
                        </svg>
                    </div>
                    <h2 className="text-lg xl:text-xl font-bold mb-2">Banking Details</h2>
                    <div className="inline-block bg-teal-400 p-2 rounded-lg xl:h-10 lg:h-10 xl:px-2 xl:py-1 self-center">
                        <p className="inline text-white text-xs">Secure Payment</p>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24"
                            viewBox="0 -960 960 960"
                            width="24"
                            className="inline-block align-middle xl:h-5 xl:w-5 lg:ml-1 " 
                        >
                            <path
                                d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Zm0-80h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240-160v-400 400Z"
                                fill="#fff"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WelcomeCard;
