import { Password, Visibility } from '@mui/icons-material';
import React, { ChangeEvent, ReactElement, useState } from 'react';

interface Props {
    label: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    value: string;
    required?: boolean;
    name: string;
    error?: string;
    style?: React.CSSProperties;
    className?: string;
    placeholder?: string;
    password?: boolean;
    disableBorder?: boolean;
    onBlur?: () => void;
    onKeyDown?: (e: any) => void;
    inputMode?: 'numeric';
    endIcon?: any;
    startIcon?: ReactElement;
    autoComplete?:
    | 'on'
    | 'off'
    | 'email'
    | 'name'
    | 'username'
    | 'new-password'
    | 'current-password'
    | 'one-time-code'
    | 'organization-title'
    | 'organization'
    | 'street-address'
    | 'address-line1'
    | 'address-line2'
    | 'address-line3'
    | 'address-level1'
    | 'address-level2'
    | 'address-level3'
    | 'address-level4'
    | 'country'
    | 'country-name'
    | 'postal-code'
    | 'cc-name'
    | 'cc-given-name'
    | 'cc-additional-name'
    | 'cc-family-name'
    | 'cc-number'
    | 'cc-exp'
    | 'cc-exp-month'
    | 'cc-exp-year'
    | 'cc-csc'
    | 'cc-type'
    | 'transaction-currency'
    | 'transaction-amount'
    | 'language'
    | 'bday'
    | 'bday-day'
    | 'bday-month'
    | 'bday-year'
    | 'sex'
    | 'tel'
    | 'tel-country-code'
    | 'tel-national'
    | 'tel-area-code'
    | 'tel-local'
    | 'tel-extension'
    | 'email'
    | 'url'
    | 'photo';
}

const TextInput = ({
    label,
    onChange,
    value,
    required,
    name,
    error,
    style = {},
    className = '',
    placeholder,
    password = false,
    disableBorder,
    onBlur,
    onKeyDown,
    inputMode,
    autoComplete,
    endIcon,
    startIcon
}: Props) => {
    const [type, setType] = useState<'text' | 'password'>(password ? 'password' : 'text');
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => setIsFocused(true);
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(false);
        onBlur && onBlur();
    };

    const borderColor = error ? 'border-red' : isFocused || value ? 'border-green' : 'border-gray-t';

    return (
        <div className='flex flex-col gap-1'>
            <div className={`relative w-full ${className} border-b-2  ${borderColor}`} style={style}>
                {label && (
                    <p className='font-semibold text-xs mb-1'>
                        {label}
                    </p>
                )}
                <input
                    className='w-full bg-white-fbg border-none border-gray-b-h h-11 px-2.5'
                    name={name}
                    type={type}
                    value={value}
                    onChange={onChange}
                    {...(onBlur && { onBlur })}
                    {...(placeholder && { placeholder })}
                    {...(onKeyDown && { onKeyDown })}
                    {...(inputMode && { inputMode })}
                    autoComplete={autoComplete ?? 'new-password'}
                />
                <>
                    {startIcon ? startIcon : null} {endIcon ? endIcon : null}
                </>
                
                {password && (
                    <div
                        className='absolute opacity-45 hover:opacity-100'
                        style={{ top: 'calc(50% + 3px)', right: 8 }}
                        onClick={() => setType(type === 'text' ? 'password' : 'text')}>
                        <Visibility />
                    </div>
                )}
            </div>
            {/* {required && <p className='text-xs font-normal text-gray-b-t'>Required</p>} */}
            {error ? <p className='text-red mt-1'>{error}</p> : null}
        </div>
    );
};

export default TextInput;
